import React from 'react'
import { Text, Icon, Flex } from '@chakra-ui/core'
import { theme } from '../../theme'
import Container from '../ResponsiveGrid/Container'

export type UserRoleIndicatorProps = {
  role: string
}

/**
 * User role indicator component
 * @param role - the actual user role [ ADMIN, SUPER_ADMIN ]
 */

const UserRoleIndicator: React.FC<UserRoleIndicatorProps> = ({ role, children }) => {
  const userRole = role.split('_')
  const displayRole = userRole
    .map((word) => {
      return word[0] + word.toLowerCase().slice(1)
    })
    .toString()
    .replace(',', ' ')

  return (
    <Container fluid={true}>
      <Flex flexDirection="row">
        {children}
        <Flex
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          alignContent="space-around"
          backgroundColor={
            role === 'SUPER_ADMIN' ? theme.colors.green[550] : theme.colors.solid.white
          }
          borderWidth="1px"
          borderColor={theme.colors.green[550]}
          marginLeft="1vw"
          minWidth="9vw"
          borderRadius="4px"
        >
          <Icon
            name="star"
            marginLeft="0.5vw"
            marginRight="0.5vw"
            color={role === 'SUPER_ADMIN' ? theme.colors.solid.white : theme.colors.green[550]}
          />
          <Text
            marginRight="0.5vw"
            color={role === 'SUPER_ADMIN' ? theme.colors.solid.white : theme.colors.green[550]}
          >
            {displayRole}
          </Text>
        </Flex>
      </Flex>
    </Container>
  )
}

export default UserRoleIndicator
