import * as React from 'react'
import { User } from '../generated/graphql'
import { useQuery } from '@apollo/react-hooks'
import getUser from '../graphql/queries/getUserProfile'

type AppContextType = {
  drawerOpen: boolean
  toggleDrawer: () => void
  userProfile?: User
  setUserProfile: React.Dispatch<React.SetStateAction<User | undefined>>
  profileImage?: string
  setProfileImage: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const AppContext = React.createContext<AppContextType>({
  drawerOpen: false,
  toggleDrawer: () => null,
  setUserProfile: () => null,
  setProfileImage: () => null
})

export const useAppContext = () => React.useContext(AppContext)

const AppProvider: React.FC<{}> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [userProfile, setUserProfile] = React.useState<User | undefined>(undefined)
  const [profileImage, setProfileImage] = React.useState<string | undefined>(undefined)

  const { sub: id } = JSON.parse(window.localStorage.getItem('user') || '{}')
  const { data } = useQuery(getUser, { variables: { id } })

  React.useEffect(() => {
    if (data && data.getUser) {
      setUserProfile(data.getUser)
    }
  }, [data])

  function toggleDrawer() {
    setDrawerOpen(!drawerOpen)
  }

  return (
    <AppContext.Provider
      value={{
        drawerOpen,
        toggleDrawer,
        userProfile,
        setUserProfile,
        profileImage,
        setProfileImage
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
