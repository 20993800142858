import { List, ListItem, Flex, Text, Image } from '@chakra-ui/core'
import * as React from 'react'
import { theme } from '../../theme'
import images from '../../theme/images'

type StyledListItem = {
  icon: string
  message: string
  user: string
  color: string
  createdAt: string
}
type StyledListProps = {
  items: StyledListItem[]
  spacing?: number
}

const iconLookup = (name: string) => {
  switch (name) {
    case 'starIcon': {
      return images.starIcon
    }
    case 'plusIcon': {
      return images.plusIcon
    }
    case 'mailIcon': {
      return images.mailIcon
    }
    case 'redCircleIcon': {
      return images.redCircleIcon
    }
    default: {
      return images.plusIcon
    }
  }
}

const NotificationList: React.FC<StyledListProps> = ({ items, spacing, ...rest }) => {
  return (
    <List spacing={spacing}>
      {items.map((item) => {
        return (
          <ListItem marginBottom="0rem">
            <Flex alignItems="center" borderBottomWidth="1px" p={2}>
              <Image marginRight="1vw" height="4vh" src={iconLookup(item.icon)} alt={item.icon} />
              <Flex flexDirection="column" p={2}>
                <Text>{item.user + ' ' + item.message}</Text>
                <Text color={theme.colors.text.light}>{item.createdAt}</Text>
              </Flex>
            </Flex>
          </ListItem>
        )
      })}
    </List>
  )
}

export default NotificationList

NotificationList.defaultProps = {
  spacing: 4
}
