import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  AWSJSON: any,
  AWSDateTime: any,
};



export type Company = {
   __typename?: 'Company',
  id: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  name: Scalars['String'],
  registrationNumber: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  adminEmail: Scalars['String'],
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  companyGroup?: Maybe<CompanyGroup>,
  drivers?: Maybe<ModelDriverConnection>,
  teamCode?: Maybe<Scalars['String']>,
  legacyId?: Maybe<Scalars['String']>,
};


export type CompanyDriversArgs = {
  filter?: Maybe<ModelDriverFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type CompanyGroup = {
   __typename?: 'CompanyGroup',
  id: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  name: Scalars['String'],
  registrationNumber: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  adminEmail: Scalars['String'],
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  companies?: Maybe<ModelCompanyConnection>,
  users?: Maybe<ModelCompanyGroupUserAssociationConnection>,
  owner?: Maybe<Scalars['String']>,
};


export type CompanyGroupCompaniesArgs = {
  filter?: Maybe<ModelCompanyFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type CompanyGroupUsersArgs = {
  filter?: Maybe<ModelCompanyGroupUserAssociationFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type CompanyGroupUserAssociation = {
   __typename?: 'CompanyGroupUserAssociation',
  id: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  user: User,
  CompanyGroup: CompanyGroup,
  owner?: Maybe<Scalars['String']>,
};

export type CreateCompanyGroupInput = {
  id?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  name: Scalars['String'],
  registrationNumber: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  adminEmail: Scalars['String'],
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
};

export type CreateCompanyGroupUserAssociationInput = {
  id?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  companyGroupUserAssociationUserId: Scalars['ID'],
  companyGroupUserAssociationCompanyGroupId: Scalars['ID'],
};

export type CreateCompanyInput = {
  id?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  name: Scalars['String'],
  registrationNumber: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  adminEmail: Scalars['String'],
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  teamCode?: Maybe<Scalars['String']>,
  legacyId?: Maybe<Scalars['String']>,
  companyCompanyGroupId?: Maybe<Scalars['ID']>,
};

export type CreateDriverInput = {
  id?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  name?: Maybe<Scalars['String']>,
  surname?: Maybe<Scalars['String']>,
  dob: Scalars['AWSDateTime'],
  nationality?: Maybe<Scalars['String']>,
  idNumber?: Maybe<Scalars['String']>,
  passportNumber?: Maybe<Scalars['String']>,
  employeeNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  jobDescription?: Maybe<Scalars['String']>,
  department?: Maybe<Scalars['String']>,
  industry?: Maybe<Scalars['String']>,
  sport?: Maybe<Scalars['String']>,
  employmentStatus?: Maybe<EmploymentStatus>,
  driverDriverCompanyId?: Maybe<Scalars['ID']>,
};

export type CreateUserInput = {
  id?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  email: Scalars['String'],
  name: Scalars['String'],
  surname: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  role: UserRole,
  roleType?: Maybe<Scalars['String']>,
  status: UserStatus,
  dibCompanies?: Maybe<Array<Maybe<Scalars['Int']>>>,
};

export type CreateUserNotificationInput = {
  id?: Maybe<Scalars['ID']>,
  createdAt: Scalars['AWSDateTime'],
  message: Scalars['String'],
  icon: Scalars['String'],
  userNotificationUserId?: Maybe<Scalars['ID']>,
};

export type DeleteCompanyGroupInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteCompanyGroupUserAssociationInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteCompanyInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteDriverInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteUserInput = {
  id?: Maybe<Scalars['ID']>,
};

export type DeleteUserNotificationInput = {
  id?: Maybe<Scalars['ID']>,
};

export type Driver = {
   __typename?: 'Driver',
  id: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  name?: Maybe<Scalars['String']>,
  surname?: Maybe<Scalars['String']>,
  dob: Scalars['AWSDateTime'],
  nationality?: Maybe<Scalars['String']>,
  idNumber?: Maybe<Scalars['String']>,
  passportNumber?: Maybe<Scalars['String']>,
  employeeNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  jobDescription?: Maybe<Scalars['String']>,
  department?: Maybe<Scalars['String']>,
  industry?: Maybe<Scalars['String']>,
  sport?: Maybe<Scalars['String']>,
  employmentStatus?: Maybe<EmploymentStatus>,
  driverCompany?: Maybe<Company>,
  owner?: Maybe<Scalars['String']>,
};

export enum EmploymentStatus {
  Employed = 'EMPLOYED',
  Expired = 'EXPIRED',
  WaitingForReference = 'WAITING_FOR_REFERENCE',
  Unemployed = 'UNEMPLOYED'
}

export type ModelBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>,
  eq?: Maybe<Scalars['Boolean']>,
};

export type ModelCompanyConnection = {
   __typename?: 'ModelCompanyConnection',
  items?: Maybe<Array<Maybe<Company>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelCompanyFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  updatedAt?: Maybe<ModelStringFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  registrationNumber?: Maybe<ModelStringFilterInput>,
  phoneNumber?: Maybe<ModelStringFilterInput>,
  adminEmail?: Maybe<ModelStringFilterInput>,
  addressLine1?: Maybe<ModelStringFilterInput>,
  addressLine2?: Maybe<ModelStringFilterInput>,
  addressLine3?: Maybe<ModelStringFilterInput>,
  addressLine4?: Maybe<ModelStringFilterInput>,
  addressLine5?: Maybe<ModelStringFilterInput>,
  teamCode?: Maybe<ModelStringFilterInput>,
  legacyId?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelCompanyFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelCompanyFilterInput>>>,
  not?: Maybe<ModelCompanyFilterInput>,
};

export type ModelCompanyGroupConnection = {
   __typename?: 'ModelCompanyGroupConnection',
  items?: Maybe<Array<Maybe<CompanyGroup>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelCompanyGroupFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  updatedAt?: Maybe<ModelStringFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  registrationNumber?: Maybe<ModelStringFilterInput>,
  phoneNumber?: Maybe<ModelStringFilterInput>,
  adminEmail?: Maybe<ModelStringFilterInput>,
  addressLine1?: Maybe<ModelStringFilterInput>,
  addressLine2?: Maybe<ModelStringFilterInput>,
  addressLine3?: Maybe<ModelStringFilterInput>,
  addressLine4?: Maybe<ModelStringFilterInput>,
  addressLine5?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelCompanyGroupFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelCompanyGroupFilterInput>>>,
  not?: Maybe<ModelCompanyGroupFilterInput>,
};

export type ModelCompanyGroupUserAssociationConnection = {
   __typename?: 'ModelCompanyGroupUserAssociationConnection',
  items?: Maybe<Array<Maybe<CompanyGroupUserAssociation>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelCompanyGroupUserAssociationFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  updatedAt?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelCompanyGroupUserAssociationFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelCompanyGroupUserAssociationFilterInput>>>,
  not?: Maybe<ModelCompanyGroupUserAssociationFilterInput>,
};

export type ModelDriverConnection = {
   __typename?: 'ModelDriverConnection',
  items?: Maybe<Array<Maybe<Driver>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelDriverFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  updatedAt?: Maybe<ModelStringFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  surname?: Maybe<ModelStringFilterInput>,
  dob?: Maybe<ModelStringFilterInput>,
  nationality?: Maybe<ModelStringFilterInput>,
  idNumber?: Maybe<ModelStringFilterInput>,
  passportNumber?: Maybe<ModelStringFilterInput>,
  employeeNumber?: Maybe<ModelStringFilterInput>,
  phoneNumber?: Maybe<ModelStringFilterInput>,
  gender?: Maybe<ModelStringFilterInput>,
  jobDescription?: Maybe<ModelStringFilterInput>,
  department?: Maybe<ModelStringFilterInput>,
  industry?: Maybe<ModelStringFilterInput>,
  sport?: Maybe<ModelStringFilterInput>,
  employmentStatus?: Maybe<ModelEmploymentStatusFilterInput>,
  and?: Maybe<Array<Maybe<ModelDriverFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelDriverFilterInput>>>,
  not?: Maybe<ModelDriverFilterInput>,
};

export type ModelEmploymentStatusFilterInput = {
  eq?: Maybe<EmploymentStatus>,
  ne?: Maybe<EmploymentStatus>,
};

export type ModelFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>,
  eq?: Maybe<Scalars['Float']>,
  le?: Maybe<Scalars['Float']>,
  lt?: Maybe<Scalars['Float']>,
  ge?: Maybe<Scalars['Float']>,
  gt?: Maybe<Scalars['Float']>,
  between?: Maybe<Array<Maybe<Scalars['Float']>>>,
};

export type ModelIdFilterInput = {
  ne?: Maybe<Scalars['ID']>,
  eq?: Maybe<Scalars['ID']>,
  le?: Maybe<Scalars['ID']>,
  lt?: Maybe<Scalars['ID']>,
  ge?: Maybe<Scalars['ID']>,
  gt?: Maybe<Scalars['ID']>,
  contains?: Maybe<Scalars['ID']>,
  notContains?: Maybe<Scalars['ID']>,
  between?: Maybe<Array<Maybe<Scalars['ID']>>>,
  beginsWith?: Maybe<Scalars['ID']>,
};

export type ModelIntFilterInput = {
  ne?: Maybe<Scalars['Int']>,
  eq?: Maybe<Scalars['Int']>,
  le?: Maybe<Scalars['Int']>,
  lt?: Maybe<Scalars['Int']>,
  ge?: Maybe<Scalars['Int']>,
  gt?: Maybe<Scalars['Int']>,
  between?: Maybe<Array<Maybe<Scalars['Int']>>>,
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringFilterInput = {
  ne?: Maybe<Scalars['String']>,
  eq?: Maybe<Scalars['String']>,
  le?: Maybe<Scalars['String']>,
  lt?: Maybe<Scalars['String']>,
  ge?: Maybe<Scalars['String']>,
  gt?: Maybe<Scalars['String']>,
  contains?: Maybe<Scalars['String']>,
  notContains?: Maybe<Scalars['String']>,
  between?: Maybe<Array<Maybe<Scalars['String']>>>,
  beginsWith?: Maybe<Scalars['String']>,
};

export type ModelUserConnection = {
   __typename?: 'ModelUserConnection',
  items?: Maybe<Array<Maybe<User>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelUserFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  updatedAt?: Maybe<ModelStringFilterInput>,
  email?: Maybe<ModelStringFilterInput>,
  name?: Maybe<ModelStringFilterInput>,
  surname?: Maybe<ModelStringFilterInput>,
  phoneNumber?: Maybe<ModelStringFilterInput>,
  addressLine1?: Maybe<ModelStringFilterInput>,
  addressLine2?: Maybe<ModelStringFilterInput>,
  addressLine3?: Maybe<ModelStringFilterInput>,
  addressLine4?: Maybe<ModelStringFilterInput>,
  addressLine5?: Maybe<ModelStringFilterInput>,
  role?: Maybe<ModelUserRoleFilterInput>,
  roleType?: Maybe<ModelStringFilterInput>,
  status?: Maybe<ModelUserStatusFilterInput>,
  dibCompanies?: Maybe<ModelIntFilterInput>,
  and?: Maybe<Array<Maybe<ModelUserFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelUserFilterInput>>>,
  not?: Maybe<ModelUserFilterInput>,
};

export type ModelUserNotificationConnection = {
   __typename?: 'ModelUserNotificationConnection',
  items?: Maybe<Array<Maybe<UserNotification>>>,
  nextToken?: Maybe<Scalars['String']>,
};

export type ModelUserNotificationFilterInput = {
  id?: Maybe<ModelIdFilterInput>,
  createdAt?: Maybe<ModelStringFilterInput>,
  message?: Maybe<ModelStringFilterInput>,
  icon?: Maybe<ModelStringFilterInput>,
  and?: Maybe<Array<Maybe<ModelUserNotificationFilterInput>>>,
  or?: Maybe<Array<Maybe<ModelUserNotificationFilterInput>>>,
  not?: Maybe<ModelUserNotificationFilterInput>,
};

export type ModelUserRoleFilterInput = {
  eq?: Maybe<UserRole>,
  ne?: Maybe<UserRole>,
};

export type ModelUserStatusFilterInput = {
  eq?: Maybe<UserStatus>,
  ne?: Maybe<UserStatus>,
};

export type Mutation = {
   __typename?: 'Mutation',
  registerDrivers?: Maybe<Scalars['AWSJSON']>,
  deleteAdminUser?: Maybe<Scalars['Boolean']>,
  createCompanyGroup?: Maybe<CompanyGroup>,
  updateCompanyGroup?: Maybe<CompanyGroup>,
  deleteCompanyGroup?: Maybe<CompanyGroup>,
  createCompany?: Maybe<Company>,
  updateCompany?: Maybe<Company>,
  deleteCompany?: Maybe<Company>,
  createUser?: Maybe<User>,
  updateUser?: Maybe<User>,
  deleteUser?: Maybe<User>,
  createCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  updateCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  deleteCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  createUserNotification?: Maybe<UserNotification>,
  updateUserNotification?: Maybe<UserNotification>,
  deleteUserNotification?: Maybe<UserNotification>,
  createDriver?: Maybe<Driver>,
  updateDriver?: Maybe<Driver>,
  deleteDriver?: Maybe<Driver>,
};


export type MutationRegisterDriversArgs = {
  companyId: Scalars['Int'],
  teamCode: Scalars['String'],
  companyName: Scalars['String'],
  drivers?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>,
  uploadUrl?: Maybe<Scalars['String']>
};


export type MutationDeleteAdminUserArgs = {
  userId: Scalars['ID'],
  email: Scalars['String']
};


export type MutationCreateCompanyGroupArgs = {
  input: CreateCompanyGroupInput
};


export type MutationUpdateCompanyGroupArgs = {
  input: UpdateCompanyGroupInput
};


export type MutationDeleteCompanyGroupArgs = {
  input: DeleteCompanyGroupInput
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput
};


export type MutationDeleteCompanyArgs = {
  input: DeleteCompanyInput
};


export type MutationCreateUserArgs = {
  input: CreateUserInput
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput
};


export type MutationCreateCompanyGroupUserAssociationArgs = {
  input: CreateCompanyGroupUserAssociationInput
};


export type MutationUpdateCompanyGroupUserAssociationArgs = {
  input: UpdateCompanyGroupUserAssociationInput
};


export type MutationDeleteCompanyGroupUserAssociationArgs = {
  input: DeleteCompanyGroupUserAssociationInput
};


export type MutationCreateUserNotificationArgs = {
  input: CreateUserNotificationInput
};


export type MutationUpdateUserNotificationArgs = {
  input: UpdateUserNotificationInput
};


export type MutationDeleteUserNotificationArgs = {
  input: DeleteUserNotificationInput
};


export type MutationCreateDriverArgs = {
  input: CreateDriverInput
};


export type MutationUpdateDriverArgs = {
  input: UpdateDriverInput
};


export type MutationDeleteDriverArgs = {
  input: DeleteDriverInput
};

export type Query = {
   __typename?: 'Query',
  getCompanies?: Maybe<Scalars['AWSJSON']>,
  getDrivers?: Maybe<Scalars['AWSJSON']>,
  getDailyEyegymTraining?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>,
  getWeeklyEyegymTraining?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>,
  getMonthlyEyegymTraining?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>,
  getCompanyGroup?: Maybe<CompanyGroup>,
  listCompanyGroups?: Maybe<ModelCompanyGroupConnection>,
  getCompany?: Maybe<Company>,
  listCompanys?: Maybe<ModelCompanyConnection>,
  getUser?: Maybe<User>,
  listUsers?: Maybe<ModelUserConnection>,
  getCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  listCompanyGroupUserAssociations?: Maybe<ModelCompanyGroupUserAssociationConnection>,
  getUserNotification?: Maybe<UserNotification>,
  listUserNotifications?: Maybe<ModelUserNotificationConnection>,
  getDriver?: Maybe<Driver>,
  listDrivers?: Maybe<ModelDriverConnection>,
};


export type QueryGetDriversArgs = {
  companyList: Scalars['String'],
  startDate: Scalars['String'],
  endDate: Scalars['String']
};


export type QueryGetDailyEyegymTrainingArgs = {
  date?: Maybe<Scalars['String']>
};


export type QueryGetWeeklyEyegymTrainingArgs = {
  teamCode: Scalars['String'],
  date?: Maybe<Scalars['String']>
};


export type QueryGetMonthlyEyegymTrainingArgs = {
  teamCode: Scalars['String'],
  date?: Maybe<Scalars['String']>
};


export type QueryGetCompanyGroupArgs = {
  id: Scalars['ID']
};


export type QueryListCompanyGroupsArgs = {
  filter?: Maybe<ModelCompanyGroupFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetCompanyArgs = {
  id: Scalars['ID']
};


export type QueryListCompanysArgs = {
  filter?: Maybe<ModelCompanyFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetUserArgs = {
  id: Scalars['ID']
};


export type QueryListUsersArgs = {
  filter?: Maybe<ModelUserFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetCompanyGroupUserAssociationArgs = {
  id: Scalars['ID']
};


export type QueryListCompanyGroupUserAssociationsArgs = {
  filter?: Maybe<ModelCompanyGroupUserAssociationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetUserNotificationArgs = {
  id: Scalars['ID']
};


export type QueryListUserNotificationsArgs = {
  filter?: Maybe<ModelUserNotificationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type QueryGetDriverArgs = {
  id: Scalars['ID']
};


export type QueryListDriversArgs = {
  filter?: Maybe<ModelDriverFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type Subscription = {
   __typename?: 'Subscription',
  onCreateCompanyGroup?: Maybe<CompanyGroup>,
  onUpdateCompanyGroup?: Maybe<CompanyGroup>,
  onDeleteCompanyGroup?: Maybe<CompanyGroup>,
  onCreateCompany?: Maybe<Company>,
  onUpdateCompany?: Maybe<Company>,
  onDeleteCompany?: Maybe<Company>,
  onCreateUser?: Maybe<User>,
  onUpdateUser?: Maybe<User>,
  onDeleteUser?: Maybe<User>,
  onCreateCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  onUpdateCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  onDeleteCompanyGroupUserAssociation?: Maybe<CompanyGroupUserAssociation>,
  onCreateUserNotification?: Maybe<UserNotification>,
  onUpdateUserNotification?: Maybe<UserNotification>,
  onDeleteUserNotification?: Maybe<UserNotification>,
  onCreateDriver?: Maybe<Driver>,
  onUpdateDriver?: Maybe<Driver>,
  onDeleteDriver?: Maybe<Driver>,
};


export type SubscriptionOnCreateCompanyGroupArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateCompanyGroupArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteCompanyGroupArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnCreateUserArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateUserArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteUserArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnCreateCompanyGroupUserAssociationArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateCompanyGroupUserAssociationArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteCompanyGroupUserAssociationArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnCreateUserNotificationArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateUserNotificationArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteUserNotificationArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnCreateDriverArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnUpdateDriverArgs = {
  owner?: Maybe<Scalars['String']>
};


export type SubscriptionOnDeleteDriverArgs = {
  owner?: Maybe<Scalars['String']>
};

export type UpdateCompanyGroupInput = {
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt?: Maybe<Scalars['AWSDateTime']>,
  name?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  adminEmail?: Maybe<Scalars['String']>,
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
};

export type UpdateCompanyGroupUserAssociationInput = {
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt?: Maybe<Scalars['AWSDateTime']>,
  companyGroupUserAssociationUserId?: Maybe<Scalars['ID']>,
  companyGroupUserAssociationCompanyGroupId?: Maybe<Scalars['ID']>,
};

export type UpdateCompanyInput = {
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt?: Maybe<Scalars['AWSDateTime']>,
  name?: Maybe<Scalars['String']>,
  registrationNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  adminEmail?: Maybe<Scalars['String']>,
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  teamCode?: Maybe<Scalars['String']>,
  legacyId?: Maybe<Scalars['String']>,
  companyCompanyGroupId?: Maybe<Scalars['ID']>,
};

export type UpdateDriverInput = {
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt?: Maybe<Scalars['AWSDateTime']>,
  name?: Maybe<Scalars['String']>,
  surname?: Maybe<Scalars['String']>,
  dob?: Maybe<Scalars['AWSDateTime']>,
  nationality?: Maybe<Scalars['String']>,
  idNumber?: Maybe<Scalars['String']>,
  passportNumber?: Maybe<Scalars['String']>,
  employeeNumber?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  gender?: Maybe<Scalars['String']>,
  jobDescription?: Maybe<Scalars['String']>,
  department?: Maybe<Scalars['String']>,
  industry?: Maybe<Scalars['String']>,
  sport?: Maybe<Scalars['String']>,
  employmentStatus?: Maybe<EmploymentStatus>,
  driverDriverCompanyId?: Maybe<Scalars['ID']>,
};

export type UpdateUserInput = {
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  updatedAt?: Maybe<Scalars['AWSDateTime']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  surname?: Maybe<Scalars['String']>,
  phoneNumber?: Maybe<Scalars['String']>,
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  role?: Maybe<UserRole>,
  roleType?: Maybe<Scalars['String']>,
  status?: Maybe<UserStatus>,
  dibCompanies?: Maybe<Array<Maybe<Scalars['Int']>>>,
};

export type UpdateUserNotificationInput = {
  id: Scalars['ID'],
  createdAt?: Maybe<Scalars['AWSDateTime']>,
  message?: Maybe<Scalars['String']>,
  icon?: Maybe<Scalars['String']>,
  userNotificationUserId?: Maybe<Scalars['ID']>,
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  updatedAt: Scalars['AWSDateTime'],
  email: Scalars['String'],
  name: Scalars['String'],
  surname: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
  addressLine1?: Maybe<Scalars['String']>,
  addressLine2?: Maybe<Scalars['String']>,
  addressLine3?: Maybe<Scalars['String']>,
  addressLine4?: Maybe<Scalars['String']>,
  addressLine5?: Maybe<Scalars['String']>,
  companyGroups?: Maybe<ModelCompanyGroupUserAssociationConnection>,
  notifications?: Maybe<ModelUserNotificationConnection>,
  role: UserRole,
  roleType?: Maybe<Scalars['String']>,
  status: UserStatus,
  dibCompanies?: Maybe<Array<Maybe<Scalars['Int']>>>,
  owner?: Maybe<Scalars['String']>,
};


export type UserCompanyGroupsArgs = {
  filter?: Maybe<ModelCompanyGroupUserAssociationFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type UserNotificationsArgs = {
  filter?: Maybe<ModelUserNotificationFilterInput>,
  sortDirection?: Maybe<ModelSortDirection>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};

export type UserNotification = {
   __typename?: 'UserNotification',
  id: Scalars['ID'],
  createdAt: Scalars['AWSDateTime'],
  user?: Maybe<User>,
  message: Scalars['String'],
  icon: Scalars['String'],
  owner?: Maybe<Scalars['String']>,
};

export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Driver = 'DRIVER'
}

export enum UserStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Archived = 'ARCHIVED'
}

export type RegisterDriversMutationVariables = {
  companyId: Scalars['Int'],
  teamCode: Scalars['String'],
  companyName: Scalars['String'],
  drivers?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>,
  uploadUrl?: Maybe<Scalars['String']>
};


export type RegisterDriversMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'registerDrivers'>
);

export type DeleteAdminUserMutationVariables = {
  userId: Scalars['ID'],
  email: Scalars['String']
};


export type DeleteAdminUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAdminUser'>
);

export type CreateCompanyGroupMutationVariables = {
  input: CreateCompanyGroupInput
};


export type CreateCompanyGroupMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type UpdateCompanyGroupMutationVariables = {
  input: UpdateCompanyGroupInput
};


export type UpdateCompanyGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type DeleteCompanyGroupMutationVariables = {
  input: DeleteCompanyGroupInput
};


export type DeleteCompanyGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput
};


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput
};


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput
};


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type CreateUserMutationVariables = {
  input: CreateUserInput
};


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type UpdateUserMutationVariables = {
  input: UpdateUserInput
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type DeleteUserMutationVariables = {
  input: DeleteUserInput
};


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type CreateCompanyGroupUserAssociationMutationVariables = {
  input: CreateCompanyGroupUserAssociationInput
};


export type CreateCompanyGroupUserAssociationMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type UpdateCompanyGroupUserAssociationMutationVariables = {
  input: UpdateCompanyGroupUserAssociationInput
};


export type UpdateCompanyGroupUserAssociationMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type DeleteCompanyGroupUserAssociationMutationVariables = {
  input: DeleteCompanyGroupUserAssociationInput
};


export type DeleteCompanyGroupUserAssociationMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type CreateUserNotificationMutationVariables = {
  input: CreateUserNotificationInput
};


export type CreateUserNotificationMutation = (
  { __typename?: 'Mutation' }
  & { createUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type UpdateUserNotificationMutationVariables = {
  input: UpdateUserNotificationInput
};


export type UpdateUserNotificationMutation = (
  { __typename?: 'Mutation' }
  & { updateUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type DeleteUserNotificationMutationVariables = {
  input: DeleteUserNotificationInput
};


export type DeleteUserNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type CreateDriverMutationVariables = {
  input: CreateDriverInput
};


export type CreateDriverMutation = (
  { __typename?: 'Mutation' }
  & { createDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type UpdateDriverMutationVariables = {
  input: UpdateDriverInput
};


export type UpdateDriverMutation = (
  { __typename?: 'Mutation' }
  & { updateDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type DeleteDriverMutationVariables = {
  input: DeleteDriverInput
};


export type DeleteDriverMutation = (
  { __typename?: 'Mutation' }
  & { deleteDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type GetCompaniesQueryVariables = {};


export type GetCompaniesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanies'>
);

export type GetDriversQueryVariables = {
  companyList: Scalars['String'],
  startDate: Scalars['String'],
  endDate: Scalars['String']
};


export type GetDriversQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDrivers'>
);

export type GetDailyEyegymTrainingQueryVariables = {
  date?: Maybe<Scalars['String']>
};


export type GetDailyEyegymTrainingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDailyEyegymTraining'>
);

export type GetWeeklyEyegymTrainingQueryVariables = {
  teamCode: Scalars['String'],
  date?: Maybe<Scalars['String']>
};


export type GetWeeklyEyegymTrainingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWeeklyEyegymTraining'>
);

export type GetMonthlyEyegymTrainingQueryVariables = {
  teamCode: Scalars['String'],
  date?: Maybe<Scalars['String']>
};


export type GetMonthlyEyegymTrainingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getMonthlyEyegymTraining'>
);

export type GetCompanyGroupQueryVariables = {
  id: Scalars['ID']
};


export type GetCompanyGroupQuery = (
  { __typename?: 'Query' }
  & { getCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type ListCompanyGroupsQueryVariables = {
  filter?: Maybe<ModelCompanyGroupFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ListCompanyGroupsQuery = (
  { __typename?: 'Query' }
  & { listCompanyGroups: Maybe<(
    { __typename?: 'ModelCompanyGroupConnection' }
    & Pick<ModelCompanyGroupConnection, 'nextToken'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>>> }
  )> }
);

export type GetCompanyQueryVariables = {
  id: Scalars['ID']
};


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type ListCompanysQueryVariables = {
  filter?: Maybe<ModelCompanyFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ListCompanysQuery = (
  { __typename?: 'Query' }
  & { listCompanys: Maybe<(
    { __typename?: 'ModelCompanyConnection' }
    & Pick<ModelCompanyConnection, 'nextToken'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )>>> }
  )> }
);

export type GetUserQueryVariables = {
  id: Scalars['ID']
};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type ListUsersQueryVariables = {
  filter?: Maybe<ModelUserFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { listUsers: Maybe<(
    { __typename?: 'ModelUserConnection' }
    & Pick<ModelUserConnection, 'nextToken'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )>>> }
  )> }
);

export type GetCompanyGroupUserAssociationQueryVariables = {
  id: Scalars['ID']
};


export type GetCompanyGroupUserAssociationQuery = (
  { __typename?: 'Query' }
  & { getCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type ListCompanyGroupUserAssociationsQueryVariables = {
  filter?: Maybe<ModelCompanyGroupUserAssociationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ListCompanyGroupUserAssociationsQuery = (
  { __typename?: 'Query' }
  & { listCompanyGroupUserAssociations: Maybe<(
    { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
    & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'CompanyGroupUserAssociation' }
      & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      ), CompanyGroup: (
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      ) }
    )>>> }
  )> }
);

export type GetUserNotificationQueryVariables = {
  id: Scalars['ID']
};


export type GetUserNotificationQuery = (
  { __typename?: 'Query' }
  & { getUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type ListUserNotificationsQueryVariables = {
  filter?: Maybe<ModelUserNotificationFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ListUserNotificationsQuery = (
  { __typename?: 'Query' }
  & { listUserNotifications: Maybe<(
    { __typename?: 'ModelUserNotificationConnection' }
    & Pick<ModelUserNotificationConnection, 'nextToken'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'UserNotification' }
      & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      )> }
    )>>> }
  )> }
);

export type GetDriverQueryVariables = {
  id: Scalars['ID']
};


export type GetDriverQuery = (
  { __typename?: 'Query' }
  & { getDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type ListDriversQueryVariables = {
  filter?: Maybe<ModelDriverFilterInput>,
  limit?: Maybe<Scalars['Int']>,
  nextToken?: Maybe<Scalars['String']>
};


export type ListDriversQuery = (
  { __typename?: 'Query' }
  & { listDrivers: Maybe<(
    { __typename?: 'ModelDriverConnection' }
    & Pick<ModelDriverConnection, 'nextToken'>
    & { items: Maybe<Array<Maybe<(
      { __typename?: 'Driver' }
      & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      & { driverCompany: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )> }
    )>>> }
  )> }
);

export type OnCreateCompanyGroupSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnCreateCompanyGroupSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnUpdateCompanyGroupSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnUpdateCompanyGroupSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnDeleteCompanyGroupSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnDeleteCompanyGroupSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteCompanyGroup: Maybe<(
    { __typename?: 'CompanyGroup' }
    & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
    & { companies: Maybe<(
      { __typename?: 'ModelCompanyConnection' }
      & Pick<ModelCompanyConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      )>>> }
    )>, users: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnCreateCompanySubscriptionVariables = {};


export type OnCreateCompanySubscription = (
  { __typename?: 'Subscription' }
  & { onCreateCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnUpdateCompanySubscriptionVariables = {};


export type OnUpdateCompanySubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnDeleteCompanySubscriptionVariables = {};


export type OnDeleteCompanySubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteCompany: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
    & { companyGroup: Maybe<(
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    )>, drivers: Maybe<(
      { __typename?: 'ModelDriverConnection' }
      & Pick<ModelDriverConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'Driver' }
        & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnCreateUserSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnCreateUserSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnUpdateUserSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnUpdateUserSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnDeleteUserSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnDeleteUserSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteUser: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
    & { companyGroups: Maybe<(
      { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
      & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'CompanyGroupUserAssociation' }
        & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
      )>>> }
    )>, notifications: Maybe<(
      { __typename?: 'ModelUserNotificationConnection' }
      & Pick<ModelUserNotificationConnection, 'nextToken'>
      & { items: Maybe<Array<Maybe<(
        { __typename?: 'UserNotification' }
        & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
      )>>> }
    )> }
  )> }
);

export type OnCreateCompanyGroupUserAssociationSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnCreateCompanyGroupUserAssociationSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type OnUpdateCompanyGroupUserAssociationSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnUpdateCompanyGroupUserAssociationSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type OnDeleteCompanyGroupUserAssociationSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnDeleteCompanyGroupUserAssociationSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteCompanyGroupUserAssociation: Maybe<(
    { __typename?: 'CompanyGroupUserAssociation' }
    & Pick<CompanyGroupUserAssociation, 'id' | 'createdAt' | 'updatedAt' | 'owner'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    ), CompanyGroup: (
      { __typename?: 'CompanyGroup' }
      & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      & { companies: Maybe<(
        { __typename?: 'ModelCompanyConnection' }
        & Pick<ModelCompanyConnection, 'nextToken'>
      )>, users: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )> }
    ) }
  )> }
);

export type OnCreateUserNotificationSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnCreateUserNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type OnUpdateUserNotificationSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnUpdateUserNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type OnDeleteUserNotificationSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnDeleteUserNotificationSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteUserNotification: Maybe<(
    { __typename?: 'UserNotification' }
    & Pick<UserNotification, 'id' | 'createdAt' | 'message' | 'icon' | 'owner'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'createdAt' | 'updatedAt' | 'email' | 'name' | 'surname' | 'phoneNumber' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'role' | 'roleType' | 'status' | 'dibCompanies' | 'owner'>
      & { companyGroups: Maybe<(
        { __typename?: 'ModelCompanyGroupUserAssociationConnection' }
        & Pick<ModelCompanyGroupUserAssociationConnection, 'nextToken'>
      )>, notifications: Maybe<(
        { __typename?: 'ModelUserNotificationConnection' }
        & Pick<ModelUserNotificationConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type OnCreateDriverSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnCreateDriverSubscription = (
  { __typename?: 'Subscription' }
  & { onCreateDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type OnUpdateDriverSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnUpdateDriverSubscription = (
  { __typename?: 'Subscription' }
  & { onUpdateDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);

export type OnDeleteDriverSubscriptionVariables = {
  owner?: Maybe<Scalars['String']>
};


export type OnDeleteDriverSubscription = (
  { __typename?: 'Subscription' }
  & { onDeleteDriver: Maybe<(
    { __typename?: 'Driver' }
    & Pick<Driver, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'surname' | 'dob' | 'nationality' | 'idNumber' | 'passportNumber' | 'employeeNumber' | 'phoneNumber' | 'gender' | 'jobDescription' | 'department' | 'industry' | 'sport' | 'employmentStatus' | 'owner'>
    & { driverCompany: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'teamCode' | 'legacyId'>
      & { companyGroup: Maybe<(
        { __typename?: 'CompanyGroup' }
        & Pick<CompanyGroup, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'registrationNumber' | 'phoneNumber' | 'adminEmail' | 'addressLine1' | 'addressLine2' | 'addressLine3' | 'addressLine4' | 'addressLine5' | 'owner'>
      )>, drivers: Maybe<(
        { __typename?: 'ModelDriverConnection' }
        & Pick<ModelDriverConnection, 'nextToken'>
      )> }
    )> }
  )> }
);


export const RegisterDriversDocument = gql`
    mutation RegisterDrivers($companyId: Int!, $teamCode: String!, $companyName: String!, $drivers: [AWSJSON], $uploadUrl: String) {
  registerDrivers(companyId: $companyId, teamCode: $teamCode, companyName: $companyName, drivers: $drivers, uploadUrl: $uploadUrl)
}
    `;
export type RegisterDriversMutationFn = ApolloReactCommon.MutationFunction<RegisterDriversMutation, RegisterDriversMutationVariables>;
export type RegisterDriversComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterDriversMutation, RegisterDriversMutationVariables>, 'mutation'>;

    export const RegisterDriversComponent = (props: RegisterDriversComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterDriversMutation, RegisterDriversMutationVariables> mutation={RegisterDriversDocument} {...props} />
    );
    
export type RegisterDriversProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RegisterDriversMutation, RegisterDriversMutationVariables> & TChildProps;
export function withRegisterDrivers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisterDriversMutation,
  RegisterDriversMutationVariables,
  RegisterDriversProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RegisterDriversMutation, RegisterDriversMutationVariables, RegisterDriversProps<TChildProps>>(RegisterDriversDocument, {
      alias: 'registerDrivers',
      ...operationOptions
    });
};

/**
 * __useRegisterDriversMutation__
 *
 * To run a mutation, you first call `useRegisterDriversMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDriversMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDriversMutation, { data, loading, error }] = useRegisterDriversMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      teamCode: // value for 'teamCode'
 *      companyName: // value for 'companyName'
 *      drivers: // value for 'drivers'
 *      uploadUrl: // value for 'uploadUrl'
 *   },
 * });
 */
export function useRegisterDriversMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterDriversMutation, RegisterDriversMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterDriversMutation, RegisterDriversMutationVariables>(RegisterDriversDocument, baseOptions);
      }
export type RegisterDriversMutationHookResult = ReturnType<typeof useRegisterDriversMutation>;
export type RegisterDriversMutationResult = ApolloReactCommon.MutationResult<RegisterDriversMutation>;
export type RegisterDriversMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterDriversMutation, RegisterDriversMutationVariables>;
export const DeleteAdminUserDocument = gql`
    mutation DeleteAdminUser($userId: ID!, $email: String!) {
  deleteAdminUser(userId: $userId, email: $email)
}
    `;
export type DeleteAdminUserMutationFn = ApolloReactCommon.MutationFunction<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;
export type DeleteAdminUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>, 'mutation'>;

    export const DeleteAdminUserComponent = (props: DeleteAdminUserComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables> mutation={DeleteAdminUserDocument} {...props} />
    );
    
export type DeleteAdminUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteAdminUserMutation, DeleteAdminUserMutationVariables> & TChildProps;
export function withDeleteAdminUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables,
  DeleteAdminUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAdminUserMutation, DeleteAdminUserMutationVariables, DeleteAdminUserProps<TChildProps>>(DeleteAdminUserDocument, {
      alias: 'deleteAdminUser',
      ...operationOptions
    });
};

/**
 * __useDeleteAdminUserMutation__
 *
 * To run a mutation, you first call `useDeleteAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminUserMutation, { data, loading, error }] = useDeleteAdminUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useDeleteAdminUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>(DeleteAdminUserDocument, baseOptions);
      }
export type DeleteAdminUserMutationHookResult = ReturnType<typeof useDeleteAdminUserMutation>;
export type DeleteAdminUserMutationResult = ApolloReactCommon.MutationResult<DeleteAdminUserMutation>;
export type DeleteAdminUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAdminUserMutation, DeleteAdminUserMutationVariables>;
export const CreateCompanyGroupDocument = gql`
    mutation CreateCompanyGroup($input: CreateCompanyGroupInput!) {
  createCompanyGroup(input: $input) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type CreateCompanyGroupMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables>;
export type CreateCompanyGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables>, 'mutation'>;

    export const CreateCompanyGroupComponent = (props: CreateCompanyGroupComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables> mutation={CreateCompanyGroupDocument} {...props} />
    );
    
export type CreateCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables> & TChildProps;
export function withCreateCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCompanyGroupMutation,
  CreateCompanyGroupMutationVariables,
  CreateCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables, CreateCompanyGroupProps<TChildProps>>(CreateCompanyGroupDocument, {
      alias: 'createCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useCreateCompanyGroupMutation__
 *
 * To run a mutation, you first call `useCreateCompanyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyGroupMutation, { data, loading, error }] = useCreateCompanyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables>(CreateCompanyGroupDocument, baseOptions);
      }
export type CreateCompanyGroupMutationHookResult = ReturnType<typeof useCreateCompanyGroupMutation>;
export type CreateCompanyGroupMutationResult = ApolloReactCommon.MutationResult<CreateCompanyGroupMutation>;
export type CreateCompanyGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyGroupMutation, CreateCompanyGroupMutationVariables>;
export const UpdateCompanyGroupDocument = gql`
    mutation UpdateCompanyGroup($input: UpdateCompanyGroupInput!) {
  updateCompanyGroup(input: $input) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type UpdateCompanyGroupMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables>;
export type UpdateCompanyGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables>, 'mutation'>;

    export const UpdateCompanyGroupComponent = (props: UpdateCompanyGroupComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables> mutation={UpdateCompanyGroupDocument} {...props} />
    );
    
export type UpdateCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables> & TChildProps;
export function withUpdateCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyGroupMutation,
  UpdateCompanyGroupMutationVariables,
  UpdateCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables, UpdateCompanyGroupProps<TChildProps>>(UpdateCompanyGroupDocument, {
      alias: 'updateCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyGroupMutation, { data, loading, error }] = useUpdateCompanyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables>(UpdateCompanyGroupDocument, baseOptions);
      }
export type UpdateCompanyGroupMutationHookResult = ReturnType<typeof useUpdateCompanyGroupMutation>;
export type UpdateCompanyGroupMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyGroupMutation>;
export type UpdateCompanyGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyGroupMutation, UpdateCompanyGroupMutationVariables>;
export const DeleteCompanyGroupDocument = gql`
    mutation DeleteCompanyGroup($input: DeleteCompanyGroupInput!) {
  deleteCompanyGroup(input: $input) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type DeleteCompanyGroupMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables>;
export type DeleteCompanyGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables>, 'mutation'>;

    export const DeleteCompanyGroupComponent = (props: DeleteCompanyGroupComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables> mutation={DeleteCompanyGroupDocument} {...props} />
    );
    
export type DeleteCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables> & TChildProps;
export function withDeleteCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCompanyGroupMutation,
  DeleteCompanyGroupMutationVariables,
  DeleteCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables, DeleteCompanyGroupProps<TChildProps>>(DeleteCompanyGroupDocument, {
      alias: 'deleteCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useDeleteCompanyGroupMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyGroupMutation, { data, loading, error }] = useDeleteCompanyGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables>(DeleteCompanyGroupDocument, baseOptions);
      }
export type DeleteCompanyGroupMutationHookResult = ReturnType<typeof useDeleteCompanyGroupMutation>;
export type DeleteCompanyGroupMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyGroupMutation>;
export type DeleteCompanyGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyGroupMutation, DeleteCompanyGroupMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type CreateCompanyMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export type CreateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyMutation, CreateCompanyMutationVariables>, 'mutation'>;

    export const CreateCompanyComponent = (props: CreateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> mutation={CreateCompanyDocument} {...props} />
    );
    
export type CreateCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCompanyMutation, CreateCompanyMutationVariables> & TChildProps;
export function withCreateCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCompanyMutation,
  CreateCompanyMutationVariables,
  CreateCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCompanyMutation, CreateCompanyMutationVariables, CreateCompanyProps<TChildProps>>(CreateCompanyDocument, {
      alias: 'createCompany',
      ...operationOptions
    });
};

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, baseOptions);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = ApolloReactCommon.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($input: UpdateCompanyInput!) {
  updateCompany(input: $input) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type UpdateCompanyMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export type UpdateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>, 'mutation'>;

    export const UpdateCompanyComponent = (props: UpdateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> mutation={UpdateCompanyDocument} {...props} />
    );
    
export type UpdateCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanyMutation, UpdateCompanyMutationVariables> & TChildProps;
export function withUpdateCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyMutation, UpdateCompanyMutationVariables, UpdateCompanyProps<TChildProps>>(UpdateCompanyDocument, {
      alias: 'updateCompany',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, baseOptions);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($input: DeleteCompanyInput!) {
  deleteCompany(input: $input) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type DeleteCompanyMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export type DeleteCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>, 'mutation'>;

    export const DeleteCompanyComponent = (props: DeleteCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCompanyMutation, DeleteCompanyMutationVariables> mutation={DeleteCompanyDocument} {...props} />
    );
    
export type DeleteCompanyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteCompanyMutation, DeleteCompanyMutationVariables> & TChildProps;
export function withDeleteCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables,
  DeleteCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCompanyMutation, DeleteCompanyMutationVariables, DeleteCompanyProps<TChildProps>>(DeleteCompanyDocument, {
      alias: 'deleteCompany',
      ...operationOptions
    });
};

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, baseOptions);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>;

    export const CreateUserComponent = (props: CreateUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserMutation, CreateUserMutationVariables> mutation={CreateUserDocument} {...props} />
    );
    
export type CreateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateUserMutation, CreateUserMutationVariables> & TChildProps;
export function withCreateUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserMutation, CreateUserMutationVariables, CreateUserProps<TChildProps>>(CreateUserDocument, {
      alias: 'createUser',
      ...operationOptions
    });
};

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export type UpdateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserMutation, UpdateUserMutationVariables> & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export type DeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserMutation, DeleteUserMutationVariables>, 'mutation'>;

    export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserMutation, DeleteUserMutationVariables> mutation={DeleteUserDocument} {...props} />
    );
    
export type DeleteUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteUserMutation, DeleteUserMutationVariables> & TChildProps;
export function withDeleteUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserMutation, DeleteUserMutationVariables, DeleteUserProps<TChildProps>>(DeleteUserDocument, {
      alias: 'deleteUser',
      ...operationOptions
    });
};

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreateCompanyGroupUserAssociationDocument = gql`
    mutation CreateCompanyGroupUserAssociation($input: CreateCompanyGroupUserAssociationInput!) {
  createCompanyGroupUserAssociation(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type CreateCompanyGroupUserAssociationMutationFn = ApolloReactCommon.MutationFunction<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables>;
export type CreateCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables>, 'mutation'>;

    export const CreateCompanyGroupUserAssociationComponent = (props: CreateCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables> mutation={CreateCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type CreateCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables> & TChildProps;
export function withCreateCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCompanyGroupUserAssociationMutation,
  CreateCompanyGroupUserAssociationMutationVariables,
  CreateCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables, CreateCompanyGroupUserAssociationProps<TChildProps>>(CreateCompanyGroupUserAssociationDocument, {
      alias: 'createCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useCreateCompanyGroupUserAssociationMutation__
 *
 * To run a mutation, you first call `useCreateCompanyGroupUserAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyGroupUserAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyGroupUserAssociationMutation, { data, loading, error }] = useCreateCompanyGroupUserAssociationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyGroupUserAssociationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables>(CreateCompanyGroupUserAssociationDocument, baseOptions);
      }
export type CreateCompanyGroupUserAssociationMutationHookResult = ReturnType<typeof useCreateCompanyGroupUserAssociationMutation>;
export type CreateCompanyGroupUserAssociationMutationResult = ApolloReactCommon.MutationResult<CreateCompanyGroupUserAssociationMutation>;
export type CreateCompanyGroupUserAssociationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCompanyGroupUserAssociationMutation, CreateCompanyGroupUserAssociationMutationVariables>;
export const UpdateCompanyGroupUserAssociationDocument = gql`
    mutation UpdateCompanyGroupUserAssociation($input: UpdateCompanyGroupUserAssociationInput!) {
  updateCompanyGroupUserAssociation(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type UpdateCompanyGroupUserAssociationMutationFn = ApolloReactCommon.MutationFunction<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables>;
export type UpdateCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables>, 'mutation'>;

    export const UpdateCompanyGroupUserAssociationComponent = (props: UpdateCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables> mutation={UpdateCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type UpdateCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables> & TChildProps;
export function withUpdateCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCompanyGroupUserAssociationMutation,
  UpdateCompanyGroupUserAssociationMutationVariables,
  UpdateCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables, UpdateCompanyGroupUserAssociationProps<TChildProps>>(UpdateCompanyGroupUserAssociationDocument, {
      alias: 'updateCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useUpdateCompanyGroupUserAssociationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyGroupUserAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyGroupUserAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyGroupUserAssociationMutation, { data, loading, error }] = useUpdateCompanyGroupUserAssociationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyGroupUserAssociationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables>(UpdateCompanyGroupUserAssociationDocument, baseOptions);
      }
export type UpdateCompanyGroupUserAssociationMutationHookResult = ReturnType<typeof useUpdateCompanyGroupUserAssociationMutation>;
export type UpdateCompanyGroupUserAssociationMutationResult = ApolloReactCommon.MutationResult<UpdateCompanyGroupUserAssociationMutation>;
export type UpdateCompanyGroupUserAssociationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCompanyGroupUserAssociationMutation, UpdateCompanyGroupUserAssociationMutationVariables>;
export const DeleteCompanyGroupUserAssociationDocument = gql`
    mutation DeleteCompanyGroupUserAssociation($input: DeleteCompanyGroupUserAssociationInput!) {
  deleteCompanyGroupUserAssociation(input: $input) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type DeleteCompanyGroupUserAssociationMutationFn = ApolloReactCommon.MutationFunction<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables>;
export type DeleteCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables>, 'mutation'>;

    export const DeleteCompanyGroupUserAssociationComponent = (props: DeleteCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables> mutation={DeleteCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type DeleteCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables> & TChildProps;
export function withDeleteCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCompanyGroupUserAssociationMutation,
  DeleteCompanyGroupUserAssociationMutationVariables,
  DeleteCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables, DeleteCompanyGroupUserAssociationProps<TChildProps>>(DeleteCompanyGroupUserAssociationDocument, {
      alias: 'deleteCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useDeleteCompanyGroupUserAssociationMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyGroupUserAssociationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyGroupUserAssociationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyGroupUserAssociationMutation, { data, loading, error }] = useDeleteCompanyGroupUserAssociationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyGroupUserAssociationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables>(DeleteCompanyGroupUserAssociationDocument, baseOptions);
      }
export type DeleteCompanyGroupUserAssociationMutationHookResult = ReturnType<typeof useDeleteCompanyGroupUserAssociationMutation>;
export type DeleteCompanyGroupUserAssociationMutationResult = ApolloReactCommon.MutationResult<DeleteCompanyGroupUserAssociationMutation>;
export type DeleteCompanyGroupUserAssociationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCompanyGroupUserAssociationMutation, DeleteCompanyGroupUserAssociationMutationVariables>;
export const CreateUserNotificationDocument = gql`
    mutation CreateUserNotification($input: CreateUserNotificationInput!) {
  createUserNotification(input: $input) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type CreateUserNotificationMutationFn = ApolloReactCommon.MutationFunction<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>;
export type CreateUserNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>, 'mutation'>;

    export const CreateUserNotificationComponent = (props: CreateUserNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserNotificationMutation, CreateUserNotificationMutationVariables> mutation={CreateUserNotificationDocument} {...props} />
    );
    
export type CreateUserNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateUserNotificationMutation, CreateUserNotificationMutationVariables> & TChildProps;
export function withCreateUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserNotificationMutation,
  CreateUserNotificationMutationVariables,
  CreateUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserNotificationMutation, CreateUserNotificationMutationVariables, CreateUserNotificationProps<TChildProps>>(CreateUserNotificationDocument, {
      alias: 'createUserNotification',
      ...operationOptions
    });
};

/**
 * __useCreateUserNotificationMutation__
 *
 * To run a mutation, you first call `useCreateUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserNotificationMutation, { data, loading, error }] = useCreateUserNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>(CreateUserNotificationDocument, baseOptions);
      }
export type CreateUserNotificationMutationHookResult = ReturnType<typeof useCreateUserNotificationMutation>;
export type CreateUserNotificationMutationResult = ApolloReactCommon.MutationResult<CreateUserNotificationMutation>;
export type CreateUserNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserNotificationMutation, CreateUserNotificationMutationVariables>;
export const UpdateUserNotificationDocument = gql`
    mutation UpdateUserNotification($input: UpdateUserNotificationInput!) {
  updateUserNotification(input: $input) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type UpdateUserNotificationMutationFn = ApolloReactCommon.MutationFunction<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables>;
export type UpdateUserNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables>, 'mutation'>;

    export const UpdateUserNotificationComponent = (props: UpdateUserNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables> mutation={UpdateUserNotificationDocument} {...props} />
    );
    
export type UpdateUserNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables> & TChildProps;
export function withUpdateUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserNotificationMutation,
  UpdateUserNotificationMutationVariables,
  UpdateUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables, UpdateUserNotificationProps<TChildProps>>(UpdateUserNotificationDocument, {
      alias: 'updateUserNotification',
      ...operationOptions
    });
};

/**
 * __useUpdateUserNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNotificationMutation, { data, loading, error }] = useUpdateUserNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables>(UpdateUserNotificationDocument, baseOptions);
      }
export type UpdateUserNotificationMutationHookResult = ReturnType<typeof useUpdateUserNotificationMutation>;
export type UpdateUserNotificationMutationResult = ApolloReactCommon.MutationResult<UpdateUserNotificationMutation>;
export type UpdateUserNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserNotificationMutation, UpdateUserNotificationMutationVariables>;
export const DeleteUserNotificationDocument = gql`
    mutation DeleteUserNotification($input: DeleteUserNotificationInput!) {
  deleteUserNotification(input: $input) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type DeleteUserNotificationMutationFn = ApolloReactCommon.MutationFunction<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables>;
export type DeleteUserNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables>, 'mutation'>;

    export const DeleteUserNotificationComponent = (props: DeleteUserNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables> mutation={DeleteUserNotificationDocument} {...props} />
    );
    
export type DeleteUserNotificationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables> & TChildProps;
export function withDeleteUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserNotificationMutation,
  DeleteUserNotificationMutationVariables,
  DeleteUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables, DeleteUserNotificationProps<TChildProps>>(DeleteUserNotificationDocument, {
      alias: 'deleteUserNotification',
      ...operationOptions
    });
};

/**
 * __useDeleteUserNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserNotificationMutation, { data, loading, error }] = useDeleteUserNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables>(DeleteUserNotificationDocument, baseOptions);
      }
export type DeleteUserNotificationMutationHookResult = ReturnType<typeof useDeleteUserNotificationMutation>;
export type DeleteUserNotificationMutationResult = ApolloReactCommon.MutationResult<DeleteUserNotificationMutation>;
export type DeleteUserNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserNotificationMutation, DeleteUserNotificationMutationVariables>;
export const CreateDriverDocument = gql`
    mutation CreateDriver($input: CreateDriverInput!) {
  createDriver(input: $input) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type CreateDriverMutationFn = ApolloReactCommon.MutationFunction<CreateDriverMutation, CreateDriverMutationVariables>;
export type CreateDriverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDriverMutation, CreateDriverMutationVariables>, 'mutation'>;

    export const CreateDriverComponent = (props: CreateDriverComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDriverMutation, CreateDriverMutationVariables> mutation={CreateDriverDocument} {...props} />
    );
    
export type CreateDriverProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateDriverMutation, CreateDriverMutationVariables> & TChildProps;
export function withCreateDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDriverMutation,
  CreateDriverMutationVariables,
  CreateDriverProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDriverMutation, CreateDriverMutationVariables, CreateDriverProps<TChildProps>>(CreateDriverDocument, {
      alias: 'createDriver',
      ...operationOptions
    });
};

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, baseOptions);
      }
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = ApolloReactCommon.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDriverMutation, CreateDriverMutationVariables>;
export const UpdateDriverDocument = gql`
    mutation UpdateDriver($input: UpdateDriverInput!) {
  updateDriver(input: $input) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type UpdateDriverMutationFn = ApolloReactCommon.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;
export type UpdateDriverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDriverMutation, UpdateDriverMutationVariables>, 'mutation'>;

    export const UpdateDriverComponent = (props: UpdateDriverComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDriverMutation, UpdateDriverMutationVariables> mutation={UpdateDriverDocument} {...props} />
    );
    
export type UpdateDriverProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateDriverMutation, UpdateDriverMutationVariables> & TChildProps;
export function withUpdateDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDriverMutation,
  UpdateDriverMutationVariables,
  UpdateDriverProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDriverMutation, UpdateDriverMutationVariables, UpdateDriverProps<TChildProps>>(UpdateDriverDocument, {
      alias: 'updateDriver',
      ...operationOptions
    });
};

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, baseOptions);
      }
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = ApolloReactCommon.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDriverMutation, UpdateDriverMutationVariables>;
export const DeleteDriverDocument = gql`
    mutation DeleteDriver($input: DeleteDriverInput!) {
  deleteDriver(input: $input) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type DeleteDriverMutationFn = ApolloReactCommon.MutationFunction<DeleteDriverMutation, DeleteDriverMutationVariables>;
export type DeleteDriverComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDriverMutation, DeleteDriverMutationVariables>, 'mutation'>;

    export const DeleteDriverComponent = (props: DeleteDriverComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDriverMutation, DeleteDriverMutationVariables> mutation={DeleteDriverDocument} {...props} />
    );
    
export type DeleteDriverProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteDriverMutation, DeleteDriverMutationVariables> & TChildProps;
export function withDeleteDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDriverMutation,
  DeleteDriverMutationVariables,
  DeleteDriverProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDriverMutation, DeleteDriverMutationVariables, DeleteDriverProps<TChildProps>>(DeleteDriverDocument, {
      alias: 'deleteDriver',
      ...operationOptions
    });
};

/**
 * __useDeleteDriverMutation__
 *
 * To run a mutation, you first call `useDeleteDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriverMutation, { data, loading, error }] = useDeleteDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDriverMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDriverMutation, DeleteDriverMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDriverMutation, DeleteDriverMutationVariables>(DeleteDriverDocument, baseOptions);
      }
export type DeleteDriverMutationHookResult = ReturnType<typeof useDeleteDriverMutation>;
export type DeleteDriverMutationResult = ApolloReactCommon.MutationResult<DeleteDriverMutation>;
export type DeleteDriverMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDriverMutation, DeleteDriverMutationVariables>;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  getCompanies
}
    `;
export type GetCompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompaniesQuery, GetCompaniesQueryVariables>, 'query'>;

    export const GetCompaniesComponent = (props: GetCompaniesComponentProps) => (
      <ApolloReactComponents.Query<GetCompaniesQuery, GetCompaniesQueryVariables> query={GetCompaniesDocument} {...props} />
    );
    
export type GetCompaniesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompaniesQuery, GetCompaniesQueryVariables> & TChildProps;
export function withGetCompanies<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompaniesQuery,
  GetCompaniesQueryVariables,
  GetCompaniesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompaniesQuery, GetCompaniesQueryVariables, GetCompaniesProps<TChildProps>>(GetCompaniesDocument, {
      alias: 'getCompanies',
      ...operationOptions
    });
};

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
      }
export function useGetCompaniesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, baseOptions);
        }
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = ApolloReactCommon.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const GetDriversDocument = gql`
    query GetDrivers($companyList: String!, $startDate: String!, $endDate: String!) {
  getDrivers(companyList: $companyList, startDate: $startDate, endDate: $endDate)
}
    `;
export type GetDriversComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDriversQuery, GetDriversQueryVariables>, 'query'> & ({ variables: GetDriversQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDriversComponent = (props: GetDriversComponentProps) => (
      <ApolloReactComponents.Query<GetDriversQuery, GetDriversQueryVariables> query={GetDriversDocument} {...props} />
    );
    
export type GetDriversProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDriversQuery, GetDriversQueryVariables> & TChildProps;
export function withGetDrivers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDriversQuery,
  GetDriversQueryVariables,
  GetDriversProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDriversQuery, GetDriversQueryVariables, GetDriversProps<TChildProps>>(GetDriversDocument, {
      alias: 'getDrivers',
      ...operationOptions
    });
};

/**
 * __useGetDriversQuery__
 *
 * To run a query within a React component, call `useGetDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriversQuery({
 *   variables: {
 *      companyList: // value for 'companyList'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetDriversQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, baseOptions);
      }
export function useGetDriversLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDriversQuery, GetDriversQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDriversQuery, GetDriversQueryVariables>(GetDriversDocument, baseOptions);
        }
export type GetDriversQueryHookResult = ReturnType<typeof useGetDriversQuery>;
export type GetDriversLazyQueryHookResult = ReturnType<typeof useGetDriversLazyQuery>;
export type GetDriversQueryResult = ApolloReactCommon.QueryResult<GetDriversQuery, GetDriversQueryVariables>;
export const GetDailyEyegymTrainingDocument = gql`
    query GetDailyEyegymTraining($date: String) {
  getDailyEyegymTraining(date: $date)
}
    `;
export type GetDailyEyegymTrainingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables>, 'query'>;

    export const GetDailyEyegymTrainingComponent = (props: GetDailyEyegymTrainingComponentProps) => (
      <ApolloReactComponents.Query<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables> query={GetDailyEyegymTrainingDocument} {...props} />
    );
    
export type GetDailyEyegymTrainingProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables> & TChildProps;
export function withGetDailyEyegymTraining<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDailyEyegymTrainingQuery,
  GetDailyEyegymTrainingQueryVariables,
  GetDailyEyegymTrainingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables, GetDailyEyegymTrainingProps<TChildProps>>(GetDailyEyegymTrainingDocument, {
      alias: 'getDailyEyegymTraining',
      ...operationOptions
    });
};

/**
 * __useGetDailyEyegymTrainingQuery__
 *
 * To run a query within a React component, call `useGetDailyEyegymTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyEyegymTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyEyegymTrainingQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetDailyEyegymTrainingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables>(GetDailyEyegymTrainingDocument, baseOptions);
      }
export function useGetDailyEyegymTrainingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables>(GetDailyEyegymTrainingDocument, baseOptions);
        }
export type GetDailyEyegymTrainingQueryHookResult = ReturnType<typeof useGetDailyEyegymTrainingQuery>;
export type GetDailyEyegymTrainingLazyQueryHookResult = ReturnType<typeof useGetDailyEyegymTrainingLazyQuery>;
export type GetDailyEyegymTrainingQueryResult = ApolloReactCommon.QueryResult<GetDailyEyegymTrainingQuery, GetDailyEyegymTrainingQueryVariables>;
export const GetWeeklyEyegymTrainingDocument = gql`
    query GetWeeklyEyegymTraining($teamCode: String!, $date: String) {
  getWeeklyEyegymTraining(teamCode: $teamCode, date: $date)
}
    `;
export type GetWeeklyEyegymTrainingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables>, 'query'> & ({ variables: GetWeeklyEyegymTrainingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetWeeklyEyegymTrainingComponent = (props: GetWeeklyEyegymTrainingComponentProps) => (
      <ApolloReactComponents.Query<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables> query={GetWeeklyEyegymTrainingDocument} {...props} />
    );
    
export type GetWeeklyEyegymTrainingProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables> & TChildProps;
export function withGetWeeklyEyegymTraining<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetWeeklyEyegymTrainingQuery,
  GetWeeklyEyegymTrainingQueryVariables,
  GetWeeklyEyegymTrainingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables, GetWeeklyEyegymTrainingProps<TChildProps>>(GetWeeklyEyegymTrainingDocument, {
      alias: 'getWeeklyEyegymTraining',
      ...operationOptions
    });
};

/**
 * __useGetWeeklyEyegymTrainingQuery__
 *
 * To run a query within a React component, call `useGetWeeklyEyegymTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyEyegymTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyEyegymTrainingQuery({
 *   variables: {
 *      teamCode: // value for 'teamCode'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetWeeklyEyegymTrainingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables>(GetWeeklyEyegymTrainingDocument, baseOptions);
      }
export function useGetWeeklyEyegymTrainingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables>(GetWeeklyEyegymTrainingDocument, baseOptions);
        }
export type GetWeeklyEyegymTrainingQueryHookResult = ReturnType<typeof useGetWeeklyEyegymTrainingQuery>;
export type GetWeeklyEyegymTrainingLazyQueryHookResult = ReturnType<typeof useGetWeeklyEyegymTrainingLazyQuery>;
export type GetWeeklyEyegymTrainingQueryResult = ApolloReactCommon.QueryResult<GetWeeklyEyegymTrainingQuery, GetWeeklyEyegymTrainingQueryVariables>;
export const GetMonthlyEyegymTrainingDocument = gql`
    query GetMonthlyEyegymTraining($teamCode: String!, $date: String) {
  getMonthlyEyegymTraining(teamCode: $teamCode, date: $date)
}
    `;
export type GetMonthlyEyegymTrainingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables>, 'query'> & ({ variables: GetMonthlyEyegymTrainingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetMonthlyEyegymTrainingComponent = (props: GetMonthlyEyegymTrainingComponentProps) => (
      <ApolloReactComponents.Query<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables> query={GetMonthlyEyegymTrainingDocument} {...props} />
    );
    
export type GetMonthlyEyegymTrainingProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables> & TChildProps;
export function withGetMonthlyEyegymTraining<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMonthlyEyegymTrainingQuery,
  GetMonthlyEyegymTrainingQueryVariables,
  GetMonthlyEyegymTrainingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables, GetMonthlyEyegymTrainingProps<TChildProps>>(GetMonthlyEyegymTrainingDocument, {
      alias: 'getMonthlyEyegymTraining',
      ...operationOptions
    });
};

/**
 * __useGetMonthlyEyegymTrainingQuery__
 *
 * To run a query within a React component, call `useGetMonthlyEyegymTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyEyegymTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyEyegymTrainingQuery({
 *   variables: {
 *      teamCode: // value for 'teamCode'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetMonthlyEyegymTrainingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables>(GetMonthlyEyegymTrainingDocument, baseOptions);
      }
export function useGetMonthlyEyegymTrainingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables>(GetMonthlyEyegymTrainingDocument, baseOptions);
        }
export type GetMonthlyEyegymTrainingQueryHookResult = ReturnType<typeof useGetMonthlyEyegymTrainingQuery>;
export type GetMonthlyEyegymTrainingLazyQueryHookResult = ReturnType<typeof useGetMonthlyEyegymTrainingLazyQuery>;
export type GetMonthlyEyegymTrainingQueryResult = ApolloReactCommon.QueryResult<GetMonthlyEyegymTrainingQuery, GetMonthlyEyegymTrainingQueryVariables>;
export const GetCompanyGroupDocument = gql`
    query GetCompanyGroup($id: ID!) {
  getCompanyGroup(id: $id) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type GetCompanyGroupComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyGroupQuery, GetCompanyGroupQueryVariables>, 'query'> & ({ variables: GetCompanyGroupQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCompanyGroupComponent = (props: GetCompanyGroupComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyGroupQuery, GetCompanyGroupQueryVariables> query={GetCompanyGroupDocument} {...props} />
    );
    
export type GetCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanyGroupQuery, GetCompanyGroupQueryVariables> & TChildProps;
export function withGetCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyGroupQuery,
  GetCompanyGroupQueryVariables,
  GetCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyGroupQuery, GetCompanyGroupQueryVariables, GetCompanyGroupProps<TChildProps>>(GetCompanyGroupDocument, {
      alias: 'getCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useGetCompanyGroupQuery__
 *
 * To run a query within a React component, call `useGetCompanyGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyGroupQuery, GetCompanyGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyGroupQuery, GetCompanyGroupQueryVariables>(GetCompanyGroupDocument, baseOptions);
      }
export function useGetCompanyGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyGroupQuery, GetCompanyGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyGroupQuery, GetCompanyGroupQueryVariables>(GetCompanyGroupDocument, baseOptions);
        }
export type GetCompanyGroupQueryHookResult = ReturnType<typeof useGetCompanyGroupQuery>;
export type GetCompanyGroupLazyQueryHookResult = ReturnType<typeof useGetCompanyGroupLazyQuery>;
export type GetCompanyGroupQueryResult = ApolloReactCommon.QueryResult<GetCompanyGroupQuery, GetCompanyGroupQueryVariables>;
export const ListCompanyGroupsDocument = gql`
    query ListCompanyGroups($filter: ModelCompanyGroupFilterInput, $limit: Int, $nextToken: String) {
  listCompanyGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    nextToken
  }
}
    `;
export type ListCompanyGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables>, 'query'>;

    export const ListCompanyGroupsComponent = (props: ListCompanyGroupsComponentProps) => (
      <ApolloReactComponents.Query<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables> query={ListCompanyGroupsDocument} {...props} />
    );
    
export type ListCompanyGroupsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables> & TChildProps;
export function withListCompanyGroups<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListCompanyGroupsQuery,
  ListCompanyGroupsQueryVariables,
  ListCompanyGroupsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables, ListCompanyGroupsProps<TChildProps>>(ListCompanyGroupsDocument, {
      alias: 'listCompanyGroups',
      ...operationOptions
    });
};

/**
 * __useListCompanyGroupsQuery__
 *
 * To run a query within a React component, call `useListCompanyGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListCompanyGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables>(ListCompanyGroupsDocument, baseOptions);
      }
export function useListCompanyGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables>(ListCompanyGroupsDocument, baseOptions);
        }
export type ListCompanyGroupsQueryHookResult = ReturnType<typeof useListCompanyGroupsQuery>;
export type ListCompanyGroupsLazyQueryHookResult = ReturnType<typeof useListCompanyGroupsLazyQuery>;
export type ListCompanyGroupsQueryResult = ApolloReactCommon.QueryResult<ListCompanyGroupsQuery, ListCompanyGroupsQueryVariables>;
export const GetCompanyDocument = gql`
    query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type GetCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyQuery, GetCompanyQueryVariables>, 'query'> & ({ variables: GetCompanyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCompanyComponent = (props: GetCompanyComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyQuery, GetCompanyQueryVariables> query={GetCompanyDocument} {...props} />
    );
    
export type GetCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanyQuery, GetCompanyQueryVariables> & TChildProps;
export function withGetCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyQuery,
  GetCompanyQueryVariables,
  GetCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyQuery, GetCompanyQueryVariables, GetCompanyProps<TChildProps>>(GetCompanyDocument, {
      alias: 'getCompany',
      ...operationOptions
    });
};

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const ListCompanysDocument = gql`
    query ListCompanys($filter: ModelCompanyFilterInput, $limit: Int, $nextToken: String) {
  listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    nextToken
  }
}
    `;
export type ListCompanysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListCompanysQuery, ListCompanysQueryVariables>, 'query'>;

    export const ListCompanysComponent = (props: ListCompanysComponentProps) => (
      <ApolloReactComponents.Query<ListCompanysQuery, ListCompanysQueryVariables> query={ListCompanysDocument} {...props} />
    );
    
export type ListCompanysProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListCompanysQuery, ListCompanysQueryVariables> & TChildProps;
export function withListCompanys<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListCompanysQuery,
  ListCompanysQueryVariables,
  ListCompanysProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListCompanysQuery, ListCompanysQueryVariables, ListCompanysProps<TChildProps>>(ListCompanysDocument, {
      alias: 'listCompanys',
      ...operationOptions
    });
};

/**
 * __useListCompanysQuery__
 *
 * To run a query within a React component, call `useListCompanysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanysQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanysQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListCompanysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListCompanysQuery, ListCompanysQueryVariables>) {
        return ApolloReactHooks.useQuery<ListCompanysQuery, ListCompanysQueryVariables>(ListCompanysDocument, baseOptions);
      }
export function useListCompanysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListCompanysQuery, ListCompanysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListCompanysQuery, ListCompanysQueryVariables>(ListCompanysDocument, baseOptions);
        }
export type ListCompanysQueryHookResult = ReturnType<typeof useListCompanysQuery>;
export type ListCompanysLazyQueryHookResult = ReturnType<typeof useListCompanysLazyQuery>;
export type ListCompanysQueryResult = ApolloReactCommon.QueryResult<ListCompanysQuery, ListCompanysQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type GetUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserQuery, GetUserQueryVariables>, 'query'> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserComponent = (props: GetUserComponentProps) => (
      <ApolloReactComponents.Query<GetUserQuery, GetUserQueryVariables> query={GetUserDocument} {...props} />
    );
    
export type GetUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserQuery, GetUserQueryVariables> & TChildProps;
export function withGetUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserQuery,
  GetUserQueryVariables,
  GetUserProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserQuery, GetUserQueryVariables, GetUserProps<TChildProps>>(GetUserDocument, {
      alias: 'getUser',
      ...operationOptions
    });
};

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    nextToken
  }
}
    `;
export type ListUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListUsersQuery, ListUsersQueryVariables>, 'query'>;

    export const ListUsersComponent = (props: ListUsersComponentProps) => (
      <ApolloReactComponents.Query<ListUsersQuery, ListUsersQueryVariables> query={ListUsersDocument} {...props} />
    );
    
export type ListUsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListUsersQuery, ListUsersQueryVariables> & TChildProps;
export function withListUsers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListUsersQuery,
  ListUsersQueryVariables,
  ListUsersProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListUsersQuery, ListUsersQueryVariables, ListUsersProps<TChildProps>>(ListUsersDocument, {
      alias: 'listUsers',
      ...operationOptions
    });
};

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, baseOptions);
      }
export function useListUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, baseOptions);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = ApolloReactCommon.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const GetCompanyGroupUserAssociationDocument = gql`
    query GetCompanyGroupUserAssociation($id: ID!) {
  getCompanyGroupUserAssociation(id: $id) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type GetCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables>, 'query'> & ({ variables: GetCompanyGroupUserAssociationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCompanyGroupUserAssociationComponent = (props: GetCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Query<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables> query={GetCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type GetCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables> & TChildProps;
export function withGetCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCompanyGroupUserAssociationQuery,
  GetCompanyGroupUserAssociationQueryVariables,
  GetCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables, GetCompanyGroupUserAssociationProps<TChildProps>>(GetCompanyGroupUserAssociationDocument, {
      alias: 'getCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useGetCompanyGroupUserAssociationQuery__
 *
 * To run a query within a React component, call `useGetCompanyGroupUserAssociationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyGroupUserAssociationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyGroupUserAssociationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyGroupUserAssociationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables>(GetCompanyGroupUserAssociationDocument, baseOptions);
      }
export function useGetCompanyGroupUserAssociationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables>(GetCompanyGroupUserAssociationDocument, baseOptions);
        }
export type GetCompanyGroupUserAssociationQueryHookResult = ReturnType<typeof useGetCompanyGroupUserAssociationQuery>;
export type GetCompanyGroupUserAssociationLazyQueryHookResult = ReturnType<typeof useGetCompanyGroupUserAssociationLazyQuery>;
export type GetCompanyGroupUserAssociationQueryResult = ApolloReactCommon.QueryResult<GetCompanyGroupUserAssociationQuery, GetCompanyGroupUserAssociationQueryVariables>;
export const ListCompanyGroupUserAssociationsDocument = gql`
    query ListCompanyGroupUserAssociations($filter: ModelCompanyGroupUserAssociationFilterInput, $limit: Int, $nextToken: String) {
  listCompanyGroupUserAssociations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      user {
        id
        createdAt
        updatedAt
        email
        name
        surname
        phoneNumber
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        role
        roleType
        status
        dibCompanies
        owner
      }
      CompanyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      owner
    }
    nextToken
  }
}
    `;
export type ListCompanyGroupUserAssociationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables>, 'query'>;

    export const ListCompanyGroupUserAssociationsComponent = (props: ListCompanyGroupUserAssociationsComponentProps) => (
      <ApolloReactComponents.Query<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables> query={ListCompanyGroupUserAssociationsDocument} {...props} />
    );
    
export type ListCompanyGroupUserAssociationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables> & TChildProps;
export function withListCompanyGroupUserAssociations<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListCompanyGroupUserAssociationsQuery,
  ListCompanyGroupUserAssociationsQueryVariables,
  ListCompanyGroupUserAssociationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables, ListCompanyGroupUserAssociationsProps<TChildProps>>(ListCompanyGroupUserAssociationsDocument, {
      alias: 'listCompanyGroupUserAssociations',
      ...operationOptions
    });
};

/**
 * __useListCompanyGroupUserAssociationsQuery__
 *
 * To run a query within a React component, call `useListCompanyGroupUserAssociationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompanyGroupUserAssociationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompanyGroupUserAssociationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListCompanyGroupUserAssociationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables>(ListCompanyGroupUserAssociationsDocument, baseOptions);
      }
export function useListCompanyGroupUserAssociationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables>(ListCompanyGroupUserAssociationsDocument, baseOptions);
        }
export type ListCompanyGroupUserAssociationsQueryHookResult = ReturnType<typeof useListCompanyGroupUserAssociationsQuery>;
export type ListCompanyGroupUserAssociationsLazyQueryHookResult = ReturnType<typeof useListCompanyGroupUserAssociationsLazyQuery>;
export type ListCompanyGroupUserAssociationsQueryResult = ApolloReactCommon.QueryResult<ListCompanyGroupUserAssociationsQuery, ListCompanyGroupUserAssociationsQueryVariables>;
export const GetUserNotificationDocument = gql`
    query GetUserNotification($id: ID!) {
  getUserNotification(id: $id) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type GetUserNotificationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserNotificationQuery, GetUserNotificationQueryVariables>, 'query'> & ({ variables: GetUserNotificationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserNotificationComponent = (props: GetUserNotificationComponentProps) => (
      <ApolloReactComponents.Query<GetUserNotificationQuery, GetUserNotificationQueryVariables> query={GetUserNotificationDocument} {...props} />
    );
    
export type GetUserNotificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetUserNotificationQuery, GetUserNotificationQueryVariables> & TChildProps;
export function withGetUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetUserNotificationQuery,
  GetUserNotificationQueryVariables,
  GetUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetUserNotificationQuery, GetUserNotificationQueryVariables, GetUserNotificationProps<TChildProps>>(GetUserNotificationDocument, {
      alias: 'getUserNotification',
      ...operationOptions
    });
};

/**
 * __useGetUserNotificationQuery__
 *
 * To run a query within a React component, call `useGetUserNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserNotificationQuery, GetUserNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserNotificationQuery, GetUserNotificationQueryVariables>(GetUserNotificationDocument, baseOptions);
      }
export function useGetUserNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserNotificationQuery, GetUserNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserNotificationQuery, GetUserNotificationQueryVariables>(GetUserNotificationDocument, baseOptions);
        }
export type GetUserNotificationQueryHookResult = ReturnType<typeof useGetUserNotificationQuery>;
export type GetUserNotificationLazyQueryHookResult = ReturnType<typeof useGetUserNotificationLazyQuery>;
export type GetUserNotificationQueryResult = ApolloReactCommon.QueryResult<GetUserNotificationQuery, GetUserNotificationQueryVariables>;
export const ListUserNotificationsDocument = gql`
    query ListUserNotifications($filter: ModelUserNotificationFilterInput, $limit: Int, $nextToken: String) {
  listUserNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      user {
        id
        createdAt
        updatedAt
        email
        name
        surname
        phoneNumber
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        role
        roleType
        status
        dibCompanies
        owner
      }
      message
      icon
      owner
    }
    nextToken
  }
}
    `;
export type ListUserNotificationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListUserNotificationsQuery, ListUserNotificationsQueryVariables>, 'query'>;

    export const ListUserNotificationsComponent = (props: ListUserNotificationsComponentProps) => (
      <ApolloReactComponents.Query<ListUserNotificationsQuery, ListUserNotificationsQueryVariables> query={ListUserNotificationsDocument} {...props} />
    );
    
export type ListUserNotificationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListUserNotificationsQuery, ListUserNotificationsQueryVariables> & TChildProps;
export function withListUserNotifications<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListUserNotificationsQuery,
  ListUserNotificationsQueryVariables,
  ListUserNotificationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListUserNotificationsQuery, ListUserNotificationsQueryVariables, ListUserNotificationsProps<TChildProps>>(ListUserNotificationsDocument, {
      alias: 'listUserNotifications',
      ...operationOptions
    });
};

/**
 * __useListUserNotificationsQuery__
 *
 * To run a query within a React component, call `useListUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListUserNotificationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListUserNotificationsQuery, ListUserNotificationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListUserNotificationsQuery, ListUserNotificationsQueryVariables>(ListUserNotificationsDocument, baseOptions);
      }
export function useListUserNotificationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListUserNotificationsQuery, ListUserNotificationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListUserNotificationsQuery, ListUserNotificationsQueryVariables>(ListUserNotificationsDocument, baseOptions);
        }
export type ListUserNotificationsQueryHookResult = ReturnType<typeof useListUserNotificationsQuery>;
export type ListUserNotificationsLazyQueryHookResult = ReturnType<typeof useListUserNotificationsLazyQuery>;
export type ListUserNotificationsQueryResult = ApolloReactCommon.QueryResult<ListUserNotificationsQuery, ListUserNotificationsQueryVariables>;
export const GetDriverDocument = gql`
    query GetDriver($id: ID!) {
  getDriver(id: $id) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type GetDriverComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDriverQuery, GetDriverQueryVariables>, 'query'> & ({ variables: GetDriverQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDriverComponent = (props: GetDriverComponentProps) => (
      <ApolloReactComponents.Query<GetDriverQuery, GetDriverQueryVariables> query={GetDriverDocument} {...props} />
    );
    
export type GetDriverProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDriverQuery, GetDriverQueryVariables> & TChildProps;
export function withGetDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDriverQuery,
  GetDriverQueryVariables,
  GetDriverProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDriverQuery, GetDriverQueryVariables, GetDriverProps<TChildProps>>(GetDriverDocument, {
      alias: 'getDriver',
      ...operationOptions
    });
};

/**
 * __useGetDriverQuery__
 *
 * To run a query within a React component, call `useGetDriverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDriverQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDriverQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDriverQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDriverQuery, GetDriverQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDriverQuery, GetDriverQueryVariables>(GetDriverDocument, baseOptions);
      }
export function useGetDriverLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDriverQuery, GetDriverQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDriverQuery, GetDriverQueryVariables>(GetDriverDocument, baseOptions);
        }
export type GetDriverQueryHookResult = ReturnType<typeof useGetDriverQuery>;
export type GetDriverLazyQueryHookResult = ReturnType<typeof useGetDriverLazyQuery>;
export type GetDriverQueryResult = ApolloReactCommon.QueryResult<GetDriverQuery, GetDriverQueryVariables>;
export const ListDriversDocument = gql`
    query ListDrivers($filter: ModelDriverFilterInput, $limit: Int, $nextToken: String) {
  listDrivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      name
      surname
      dob
      nationality
      idNumber
      passportNumber
      employeeNumber
      phoneNumber
      gender
      jobDescription
      department
      industry
      sport
      employmentStatus
      driverCompany {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      owner
    }
    nextToken
  }
}
    `;
export type ListDriversComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ListDriversQuery, ListDriversQueryVariables>, 'query'>;

    export const ListDriversComponent = (props: ListDriversComponentProps) => (
      <ApolloReactComponents.Query<ListDriversQuery, ListDriversQueryVariables> query={ListDriversDocument} {...props} />
    );
    
export type ListDriversProps<TChildProps = {}> = ApolloReactHoc.DataProps<ListDriversQuery, ListDriversQueryVariables> & TChildProps;
export function withListDrivers<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ListDriversQuery,
  ListDriversQueryVariables,
  ListDriversProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ListDriversQuery, ListDriversQueryVariables, ListDriversProps<TChildProps>>(ListDriversDocument, {
      alias: 'listDrivers',
      ...operationOptions
    });
};

/**
 * __useListDriversQuery__
 *
 * To run a query within a React component, call `useListDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDriversQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListDriversQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListDriversQuery, ListDriversQueryVariables>) {
        return ApolloReactHooks.useQuery<ListDriversQuery, ListDriversQueryVariables>(ListDriversDocument, baseOptions);
      }
export function useListDriversLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListDriversQuery, ListDriversQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListDriversQuery, ListDriversQueryVariables>(ListDriversDocument, baseOptions);
        }
export type ListDriversQueryHookResult = ReturnType<typeof useListDriversQuery>;
export type ListDriversLazyQueryHookResult = ReturnType<typeof useListDriversLazyQuery>;
export type ListDriversQueryResult = ApolloReactCommon.QueryResult<ListDriversQuery, ListDriversQueryVariables>;
export const OnCreateCompanyGroupDocument = gql`
    subscription OnCreateCompanyGroup($owner: String) {
  onCreateCompanyGroup(owner: $owner) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type OnCreateCompanyGroupComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnCreateCompanyGroupSubscription, OnCreateCompanyGroupSubscriptionVariables>, 'subscription'>;

    export const OnCreateCompanyGroupComponent = (props: OnCreateCompanyGroupComponentProps) => (
      <ApolloReactComponents.Subscription<OnCreateCompanyGroupSubscription, OnCreateCompanyGroupSubscriptionVariables> subscription={OnCreateCompanyGroupDocument} {...props} />
    );
    
export type OnCreateCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnCreateCompanyGroupSubscription, OnCreateCompanyGroupSubscriptionVariables> & TChildProps;
export function withOnCreateCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnCreateCompanyGroupSubscription,
  OnCreateCompanyGroupSubscriptionVariables,
  OnCreateCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnCreateCompanyGroupSubscription, OnCreateCompanyGroupSubscriptionVariables, OnCreateCompanyGroupProps<TChildProps>>(OnCreateCompanyGroupDocument, {
      alias: 'onCreateCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useOnCreateCompanyGroupSubscription__
 *
 * To run a query within a React component, call `useOnCreateCompanyGroupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateCompanyGroupSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateCompanyGroupSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnCreateCompanyGroupSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateCompanyGroupSubscription, OnCreateCompanyGroupSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateCompanyGroupSubscription, OnCreateCompanyGroupSubscriptionVariables>(OnCreateCompanyGroupDocument, baseOptions);
      }
export type OnCreateCompanyGroupSubscriptionHookResult = ReturnType<typeof useOnCreateCompanyGroupSubscription>;
export type OnCreateCompanyGroupSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateCompanyGroupSubscription>;
export const OnUpdateCompanyGroupDocument = gql`
    subscription OnUpdateCompanyGroup($owner: String) {
  onUpdateCompanyGroup(owner: $owner) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type OnUpdateCompanyGroupComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnUpdateCompanyGroupSubscription, OnUpdateCompanyGroupSubscriptionVariables>, 'subscription'>;

    export const OnUpdateCompanyGroupComponent = (props: OnUpdateCompanyGroupComponentProps) => (
      <ApolloReactComponents.Subscription<OnUpdateCompanyGroupSubscription, OnUpdateCompanyGroupSubscriptionVariables> subscription={OnUpdateCompanyGroupDocument} {...props} />
    );
    
export type OnUpdateCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUpdateCompanyGroupSubscription, OnUpdateCompanyGroupSubscriptionVariables> & TChildProps;
export function withOnUpdateCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUpdateCompanyGroupSubscription,
  OnUpdateCompanyGroupSubscriptionVariables,
  OnUpdateCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUpdateCompanyGroupSubscription, OnUpdateCompanyGroupSubscriptionVariables, OnUpdateCompanyGroupProps<TChildProps>>(OnUpdateCompanyGroupDocument, {
      alias: 'onUpdateCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useOnUpdateCompanyGroupSubscription__
 *
 * To run a query within a React component, call `useOnUpdateCompanyGroupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCompanyGroupSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCompanyGroupSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnUpdateCompanyGroupSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateCompanyGroupSubscription, OnUpdateCompanyGroupSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateCompanyGroupSubscription, OnUpdateCompanyGroupSubscriptionVariables>(OnUpdateCompanyGroupDocument, baseOptions);
      }
export type OnUpdateCompanyGroupSubscriptionHookResult = ReturnType<typeof useOnUpdateCompanyGroupSubscription>;
export type OnUpdateCompanyGroupSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateCompanyGroupSubscription>;
export const OnDeleteCompanyGroupDocument = gql`
    subscription OnDeleteCompanyGroup($owner: String) {
  onDeleteCompanyGroup(owner: $owner) {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companies {
      items {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        teamCode
        legacyId
      }
      nextToken
    }
    users {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    owner
  }
}
    `;
export type OnDeleteCompanyGroupComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnDeleteCompanyGroupSubscription, OnDeleteCompanyGroupSubscriptionVariables>, 'subscription'>;

    export const OnDeleteCompanyGroupComponent = (props: OnDeleteCompanyGroupComponentProps) => (
      <ApolloReactComponents.Subscription<OnDeleteCompanyGroupSubscription, OnDeleteCompanyGroupSubscriptionVariables> subscription={OnDeleteCompanyGroupDocument} {...props} />
    );
    
export type OnDeleteCompanyGroupProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnDeleteCompanyGroupSubscription, OnDeleteCompanyGroupSubscriptionVariables> & TChildProps;
export function withOnDeleteCompanyGroup<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnDeleteCompanyGroupSubscription,
  OnDeleteCompanyGroupSubscriptionVariables,
  OnDeleteCompanyGroupProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnDeleteCompanyGroupSubscription, OnDeleteCompanyGroupSubscriptionVariables, OnDeleteCompanyGroupProps<TChildProps>>(OnDeleteCompanyGroupDocument, {
      alias: 'onDeleteCompanyGroup',
      ...operationOptions
    });
};

/**
 * __useOnDeleteCompanyGroupSubscription__
 *
 * To run a query within a React component, call `useOnDeleteCompanyGroupSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteCompanyGroupSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteCompanyGroupSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnDeleteCompanyGroupSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteCompanyGroupSubscription, OnDeleteCompanyGroupSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteCompanyGroupSubscription, OnDeleteCompanyGroupSubscriptionVariables>(OnDeleteCompanyGroupDocument, baseOptions);
      }
export type OnDeleteCompanyGroupSubscriptionHookResult = ReturnType<typeof useOnDeleteCompanyGroupSubscription>;
export type OnDeleteCompanyGroupSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteCompanyGroupSubscription>;
export const OnCreateCompanyDocument = gql`
    subscription OnCreateCompany {
  onCreateCompany {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type OnCreateCompanyComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnCreateCompanySubscription, OnCreateCompanySubscriptionVariables>, 'subscription'>;

    export const OnCreateCompanyComponent = (props: OnCreateCompanyComponentProps) => (
      <ApolloReactComponents.Subscription<OnCreateCompanySubscription, OnCreateCompanySubscriptionVariables> subscription={OnCreateCompanyDocument} {...props} />
    );
    
export type OnCreateCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnCreateCompanySubscription, OnCreateCompanySubscriptionVariables> & TChildProps;
export function withOnCreateCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnCreateCompanySubscription,
  OnCreateCompanySubscriptionVariables,
  OnCreateCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnCreateCompanySubscription, OnCreateCompanySubscriptionVariables, OnCreateCompanyProps<TChildProps>>(OnCreateCompanyDocument, {
      alias: 'onCreateCompany',
      ...operationOptions
    });
};

/**
 * __useOnCreateCompanySubscription__
 *
 * To run a query within a React component, call `useOnCreateCompanySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateCompanySubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateCompanySubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnCreateCompanySubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateCompanySubscription, OnCreateCompanySubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateCompanySubscription, OnCreateCompanySubscriptionVariables>(OnCreateCompanyDocument, baseOptions);
      }
export type OnCreateCompanySubscriptionHookResult = ReturnType<typeof useOnCreateCompanySubscription>;
export type OnCreateCompanySubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateCompanySubscription>;
export const OnUpdateCompanyDocument = gql`
    subscription OnUpdateCompany {
  onUpdateCompany {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type OnUpdateCompanyComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnUpdateCompanySubscription, OnUpdateCompanySubscriptionVariables>, 'subscription'>;

    export const OnUpdateCompanyComponent = (props: OnUpdateCompanyComponentProps) => (
      <ApolloReactComponents.Subscription<OnUpdateCompanySubscription, OnUpdateCompanySubscriptionVariables> subscription={OnUpdateCompanyDocument} {...props} />
    );
    
export type OnUpdateCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUpdateCompanySubscription, OnUpdateCompanySubscriptionVariables> & TChildProps;
export function withOnUpdateCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUpdateCompanySubscription,
  OnUpdateCompanySubscriptionVariables,
  OnUpdateCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUpdateCompanySubscription, OnUpdateCompanySubscriptionVariables, OnUpdateCompanyProps<TChildProps>>(OnUpdateCompanyDocument, {
      alias: 'onUpdateCompany',
      ...operationOptions
    });
};

/**
 * __useOnUpdateCompanySubscription__
 *
 * To run a query within a React component, call `useOnUpdateCompanySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCompanySubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCompanySubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateCompanySubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateCompanySubscription, OnUpdateCompanySubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateCompanySubscription, OnUpdateCompanySubscriptionVariables>(OnUpdateCompanyDocument, baseOptions);
      }
export type OnUpdateCompanySubscriptionHookResult = ReturnType<typeof useOnUpdateCompanySubscription>;
export type OnUpdateCompanySubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateCompanySubscription>;
export const OnDeleteCompanyDocument = gql`
    subscription OnDeleteCompany {
  onDeleteCompany {
    id
    createdAt
    updatedAt
    name
    registrationNumber
    phoneNumber
    adminEmail
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    drivers {
      items {
        id
        createdAt
        updatedAt
        name
        surname
        dob
        nationality
        idNumber
        passportNumber
        employeeNumber
        phoneNumber
        gender
        jobDescription
        department
        industry
        sport
        employmentStatus
        owner
      }
      nextToken
    }
    teamCode
    legacyId
  }
}
    `;
export type OnDeleteCompanyComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnDeleteCompanySubscription, OnDeleteCompanySubscriptionVariables>, 'subscription'>;

    export const OnDeleteCompanyComponent = (props: OnDeleteCompanyComponentProps) => (
      <ApolloReactComponents.Subscription<OnDeleteCompanySubscription, OnDeleteCompanySubscriptionVariables> subscription={OnDeleteCompanyDocument} {...props} />
    );
    
export type OnDeleteCompanyProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnDeleteCompanySubscription, OnDeleteCompanySubscriptionVariables> & TChildProps;
export function withOnDeleteCompany<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnDeleteCompanySubscription,
  OnDeleteCompanySubscriptionVariables,
  OnDeleteCompanyProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnDeleteCompanySubscription, OnDeleteCompanySubscriptionVariables, OnDeleteCompanyProps<TChildProps>>(OnDeleteCompanyDocument, {
      alias: 'onDeleteCompany',
      ...operationOptions
    });
};

/**
 * __useOnDeleteCompanySubscription__
 *
 * To run a query within a React component, call `useOnDeleteCompanySubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteCompanySubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteCompanySubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnDeleteCompanySubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteCompanySubscription, OnDeleteCompanySubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteCompanySubscription, OnDeleteCompanySubscriptionVariables>(OnDeleteCompanyDocument, baseOptions);
      }
export type OnDeleteCompanySubscriptionHookResult = ReturnType<typeof useOnDeleteCompanySubscription>;
export type OnDeleteCompanySubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteCompanySubscription>;
export const OnCreateUserDocument = gql`
    subscription OnCreateUser($owner: String) {
  onCreateUser(owner: $owner) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type OnCreateUserComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnCreateUserSubscription, OnCreateUserSubscriptionVariables>, 'subscription'>;

    export const OnCreateUserComponent = (props: OnCreateUserComponentProps) => (
      <ApolloReactComponents.Subscription<OnCreateUserSubscription, OnCreateUserSubscriptionVariables> subscription={OnCreateUserDocument} {...props} />
    );
    
export type OnCreateUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnCreateUserSubscription, OnCreateUserSubscriptionVariables> & TChildProps;
export function withOnCreateUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnCreateUserSubscription,
  OnCreateUserSubscriptionVariables,
  OnCreateUserProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnCreateUserSubscription, OnCreateUserSubscriptionVariables, OnCreateUserProps<TChildProps>>(OnCreateUserDocument, {
      alias: 'onCreateUser',
      ...operationOptions
    });
};

/**
 * __useOnCreateUserSubscription__
 *
 * To run a query within a React component, call `useOnCreateUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateUserSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnCreateUserSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateUserSubscription, OnCreateUserSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateUserSubscription, OnCreateUserSubscriptionVariables>(OnCreateUserDocument, baseOptions);
      }
export type OnCreateUserSubscriptionHookResult = ReturnType<typeof useOnCreateUserSubscription>;
export type OnCreateUserSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateUserSubscription>;
export const OnUpdateUserDocument = gql`
    subscription OnUpdateUser($owner: String) {
  onUpdateUser(owner: $owner) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type OnUpdateUserComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables>, 'subscription'>;

    export const OnUpdateUserComponent = (props: OnUpdateUserComponentProps) => (
      <ApolloReactComponents.Subscription<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables> subscription={OnUpdateUserDocument} {...props} />
    );
    
export type OnUpdateUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables> & TChildProps;
export function withOnUpdateUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUpdateUserSubscription,
  OnUpdateUserSubscriptionVariables,
  OnUpdateUserProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables, OnUpdateUserProps<TChildProps>>(OnUpdateUserDocument, {
      alias: 'onUpdateUser',
      ...operationOptions
    });
};

/**
 * __useOnUpdateUserSubscription__
 *
 * To run a query within a React component, call `useOnUpdateUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateUserSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnUpdateUserSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateUserSubscription, OnUpdateUserSubscriptionVariables>(OnUpdateUserDocument, baseOptions);
      }
export type OnUpdateUserSubscriptionHookResult = ReturnType<typeof useOnUpdateUserSubscription>;
export type OnUpdateUserSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateUserSubscription>;
export const OnDeleteUserDocument = gql`
    subscription OnDeleteUser($owner: String) {
  onDeleteUser(owner: $owner) {
    id
    createdAt
    updatedAt
    email
    name
    surname
    phoneNumber
    addressLine1
    addressLine2
    addressLine3
    addressLine4
    addressLine5
    companyGroups {
      items {
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
    notifications {
      items {
        id
        createdAt
        message
        icon
        owner
      }
      nextToken
    }
    role
    roleType
    status
    dibCompanies
    owner
  }
}
    `;
export type OnDeleteUserComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables>, 'subscription'>;

    export const OnDeleteUserComponent = (props: OnDeleteUserComponentProps) => (
      <ApolloReactComponents.Subscription<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables> subscription={OnDeleteUserDocument} {...props} />
    );
    
export type OnDeleteUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables> & TChildProps;
export function withOnDeleteUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnDeleteUserSubscription,
  OnDeleteUserSubscriptionVariables,
  OnDeleteUserProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables, OnDeleteUserProps<TChildProps>>(OnDeleteUserDocument, {
      alias: 'onDeleteUser',
      ...operationOptions
    });
};

/**
 * __useOnDeleteUserSubscription__
 *
 * To run a query within a React component, call `useOnDeleteUserSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteUserSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteUserSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnDeleteUserSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteUserSubscription, OnDeleteUserSubscriptionVariables>(OnDeleteUserDocument, baseOptions);
      }
export type OnDeleteUserSubscriptionHookResult = ReturnType<typeof useOnDeleteUserSubscription>;
export type OnDeleteUserSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteUserSubscription>;
export const OnCreateCompanyGroupUserAssociationDocument = gql`
    subscription OnCreateCompanyGroupUserAssociation($owner: String) {
  onCreateCompanyGroupUserAssociation(owner: $owner) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type OnCreateCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnCreateCompanyGroupUserAssociationSubscription, OnCreateCompanyGroupUserAssociationSubscriptionVariables>, 'subscription'>;

    export const OnCreateCompanyGroupUserAssociationComponent = (props: OnCreateCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Subscription<OnCreateCompanyGroupUserAssociationSubscription, OnCreateCompanyGroupUserAssociationSubscriptionVariables> subscription={OnCreateCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type OnCreateCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnCreateCompanyGroupUserAssociationSubscription, OnCreateCompanyGroupUserAssociationSubscriptionVariables> & TChildProps;
export function withOnCreateCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnCreateCompanyGroupUserAssociationSubscription,
  OnCreateCompanyGroupUserAssociationSubscriptionVariables,
  OnCreateCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnCreateCompanyGroupUserAssociationSubscription, OnCreateCompanyGroupUserAssociationSubscriptionVariables, OnCreateCompanyGroupUserAssociationProps<TChildProps>>(OnCreateCompanyGroupUserAssociationDocument, {
      alias: 'onCreateCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useOnCreateCompanyGroupUserAssociationSubscription__
 *
 * To run a query within a React component, call `useOnCreateCompanyGroupUserAssociationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateCompanyGroupUserAssociationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateCompanyGroupUserAssociationSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnCreateCompanyGroupUserAssociationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateCompanyGroupUserAssociationSubscription, OnCreateCompanyGroupUserAssociationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateCompanyGroupUserAssociationSubscription, OnCreateCompanyGroupUserAssociationSubscriptionVariables>(OnCreateCompanyGroupUserAssociationDocument, baseOptions);
      }
export type OnCreateCompanyGroupUserAssociationSubscriptionHookResult = ReturnType<typeof useOnCreateCompanyGroupUserAssociationSubscription>;
export type OnCreateCompanyGroupUserAssociationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateCompanyGroupUserAssociationSubscription>;
export const OnUpdateCompanyGroupUserAssociationDocument = gql`
    subscription OnUpdateCompanyGroupUserAssociation($owner: String) {
  onUpdateCompanyGroupUserAssociation(owner: $owner) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type OnUpdateCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnUpdateCompanyGroupUserAssociationSubscription, OnUpdateCompanyGroupUserAssociationSubscriptionVariables>, 'subscription'>;

    export const OnUpdateCompanyGroupUserAssociationComponent = (props: OnUpdateCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Subscription<OnUpdateCompanyGroupUserAssociationSubscription, OnUpdateCompanyGroupUserAssociationSubscriptionVariables> subscription={OnUpdateCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type OnUpdateCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUpdateCompanyGroupUserAssociationSubscription, OnUpdateCompanyGroupUserAssociationSubscriptionVariables> & TChildProps;
export function withOnUpdateCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUpdateCompanyGroupUserAssociationSubscription,
  OnUpdateCompanyGroupUserAssociationSubscriptionVariables,
  OnUpdateCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUpdateCompanyGroupUserAssociationSubscription, OnUpdateCompanyGroupUserAssociationSubscriptionVariables, OnUpdateCompanyGroupUserAssociationProps<TChildProps>>(OnUpdateCompanyGroupUserAssociationDocument, {
      alias: 'onUpdateCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useOnUpdateCompanyGroupUserAssociationSubscription__
 *
 * To run a query within a React component, call `useOnUpdateCompanyGroupUserAssociationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCompanyGroupUserAssociationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCompanyGroupUserAssociationSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnUpdateCompanyGroupUserAssociationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateCompanyGroupUserAssociationSubscription, OnUpdateCompanyGroupUserAssociationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateCompanyGroupUserAssociationSubscription, OnUpdateCompanyGroupUserAssociationSubscriptionVariables>(OnUpdateCompanyGroupUserAssociationDocument, baseOptions);
      }
export type OnUpdateCompanyGroupUserAssociationSubscriptionHookResult = ReturnType<typeof useOnUpdateCompanyGroupUserAssociationSubscription>;
export type OnUpdateCompanyGroupUserAssociationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateCompanyGroupUserAssociationSubscription>;
export const OnDeleteCompanyGroupUserAssociationDocument = gql`
    subscription OnDeleteCompanyGroupUserAssociation($owner: String) {
  onDeleteCompanyGroupUserAssociation(owner: $owner) {
    id
    createdAt
    updatedAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    CompanyGroup {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companies {
        nextToken
      }
      users {
        nextToken
      }
      owner
    }
    owner
  }
}
    `;
export type OnDeleteCompanyGroupUserAssociationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnDeleteCompanyGroupUserAssociationSubscription, OnDeleteCompanyGroupUserAssociationSubscriptionVariables>, 'subscription'>;

    export const OnDeleteCompanyGroupUserAssociationComponent = (props: OnDeleteCompanyGroupUserAssociationComponentProps) => (
      <ApolloReactComponents.Subscription<OnDeleteCompanyGroupUserAssociationSubscription, OnDeleteCompanyGroupUserAssociationSubscriptionVariables> subscription={OnDeleteCompanyGroupUserAssociationDocument} {...props} />
    );
    
export type OnDeleteCompanyGroupUserAssociationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnDeleteCompanyGroupUserAssociationSubscription, OnDeleteCompanyGroupUserAssociationSubscriptionVariables> & TChildProps;
export function withOnDeleteCompanyGroupUserAssociation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnDeleteCompanyGroupUserAssociationSubscription,
  OnDeleteCompanyGroupUserAssociationSubscriptionVariables,
  OnDeleteCompanyGroupUserAssociationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnDeleteCompanyGroupUserAssociationSubscription, OnDeleteCompanyGroupUserAssociationSubscriptionVariables, OnDeleteCompanyGroupUserAssociationProps<TChildProps>>(OnDeleteCompanyGroupUserAssociationDocument, {
      alias: 'onDeleteCompanyGroupUserAssociation',
      ...operationOptions
    });
};

/**
 * __useOnDeleteCompanyGroupUserAssociationSubscription__
 *
 * To run a query within a React component, call `useOnDeleteCompanyGroupUserAssociationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteCompanyGroupUserAssociationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteCompanyGroupUserAssociationSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnDeleteCompanyGroupUserAssociationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteCompanyGroupUserAssociationSubscription, OnDeleteCompanyGroupUserAssociationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteCompanyGroupUserAssociationSubscription, OnDeleteCompanyGroupUserAssociationSubscriptionVariables>(OnDeleteCompanyGroupUserAssociationDocument, baseOptions);
      }
export type OnDeleteCompanyGroupUserAssociationSubscriptionHookResult = ReturnType<typeof useOnDeleteCompanyGroupUserAssociationSubscription>;
export type OnDeleteCompanyGroupUserAssociationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteCompanyGroupUserAssociationSubscription>;
export const OnCreateUserNotificationDocument = gql`
    subscription OnCreateUserNotification($owner: String) {
  onCreateUserNotification(owner: $owner) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type OnCreateUserNotificationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnCreateUserNotificationSubscription, OnCreateUserNotificationSubscriptionVariables>, 'subscription'>;

    export const OnCreateUserNotificationComponent = (props: OnCreateUserNotificationComponentProps) => (
      <ApolloReactComponents.Subscription<OnCreateUserNotificationSubscription, OnCreateUserNotificationSubscriptionVariables> subscription={OnCreateUserNotificationDocument} {...props} />
    );
    
export type OnCreateUserNotificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnCreateUserNotificationSubscription, OnCreateUserNotificationSubscriptionVariables> & TChildProps;
export function withOnCreateUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnCreateUserNotificationSubscription,
  OnCreateUserNotificationSubscriptionVariables,
  OnCreateUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnCreateUserNotificationSubscription, OnCreateUserNotificationSubscriptionVariables, OnCreateUserNotificationProps<TChildProps>>(OnCreateUserNotificationDocument, {
      alias: 'onCreateUserNotification',
      ...operationOptions
    });
};

/**
 * __useOnCreateUserNotificationSubscription__
 *
 * To run a query within a React component, call `useOnCreateUserNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateUserNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateUserNotificationSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnCreateUserNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateUserNotificationSubscription, OnCreateUserNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateUserNotificationSubscription, OnCreateUserNotificationSubscriptionVariables>(OnCreateUserNotificationDocument, baseOptions);
      }
export type OnCreateUserNotificationSubscriptionHookResult = ReturnType<typeof useOnCreateUserNotificationSubscription>;
export type OnCreateUserNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateUserNotificationSubscription>;
export const OnUpdateUserNotificationDocument = gql`
    subscription OnUpdateUserNotification($owner: String) {
  onUpdateUserNotification(owner: $owner) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type OnUpdateUserNotificationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnUpdateUserNotificationSubscription, OnUpdateUserNotificationSubscriptionVariables>, 'subscription'>;

    export const OnUpdateUserNotificationComponent = (props: OnUpdateUserNotificationComponentProps) => (
      <ApolloReactComponents.Subscription<OnUpdateUserNotificationSubscription, OnUpdateUserNotificationSubscriptionVariables> subscription={OnUpdateUserNotificationDocument} {...props} />
    );
    
export type OnUpdateUserNotificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUpdateUserNotificationSubscription, OnUpdateUserNotificationSubscriptionVariables> & TChildProps;
export function withOnUpdateUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUpdateUserNotificationSubscription,
  OnUpdateUserNotificationSubscriptionVariables,
  OnUpdateUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUpdateUserNotificationSubscription, OnUpdateUserNotificationSubscriptionVariables, OnUpdateUserNotificationProps<TChildProps>>(OnUpdateUserNotificationDocument, {
      alias: 'onUpdateUserNotification',
      ...operationOptions
    });
};

/**
 * __useOnUpdateUserNotificationSubscription__
 *
 * To run a query within a React component, call `useOnUpdateUserNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateUserNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateUserNotificationSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnUpdateUserNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateUserNotificationSubscription, OnUpdateUserNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateUserNotificationSubscription, OnUpdateUserNotificationSubscriptionVariables>(OnUpdateUserNotificationDocument, baseOptions);
      }
export type OnUpdateUserNotificationSubscriptionHookResult = ReturnType<typeof useOnUpdateUserNotificationSubscription>;
export type OnUpdateUserNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateUserNotificationSubscription>;
export const OnDeleteUserNotificationDocument = gql`
    subscription OnDeleteUserNotification($owner: String) {
  onDeleteUserNotification(owner: $owner) {
    id
    createdAt
    user {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroups {
        nextToken
      }
      notifications {
        nextToken
      }
      role
      roleType
      status
      dibCompanies
      owner
    }
    message
    icon
    owner
  }
}
    `;
export type OnDeleteUserNotificationComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnDeleteUserNotificationSubscription, OnDeleteUserNotificationSubscriptionVariables>, 'subscription'>;

    export const OnDeleteUserNotificationComponent = (props: OnDeleteUserNotificationComponentProps) => (
      <ApolloReactComponents.Subscription<OnDeleteUserNotificationSubscription, OnDeleteUserNotificationSubscriptionVariables> subscription={OnDeleteUserNotificationDocument} {...props} />
    );
    
export type OnDeleteUserNotificationProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnDeleteUserNotificationSubscription, OnDeleteUserNotificationSubscriptionVariables> & TChildProps;
export function withOnDeleteUserNotification<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnDeleteUserNotificationSubscription,
  OnDeleteUserNotificationSubscriptionVariables,
  OnDeleteUserNotificationProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnDeleteUserNotificationSubscription, OnDeleteUserNotificationSubscriptionVariables, OnDeleteUserNotificationProps<TChildProps>>(OnDeleteUserNotificationDocument, {
      alias: 'onDeleteUserNotification',
      ...operationOptions
    });
};

/**
 * __useOnDeleteUserNotificationSubscription__
 *
 * To run a query within a React component, call `useOnDeleteUserNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteUserNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteUserNotificationSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnDeleteUserNotificationSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteUserNotificationSubscription, OnDeleteUserNotificationSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteUserNotificationSubscription, OnDeleteUserNotificationSubscriptionVariables>(OnDeleteUserNotificationDocument, baseOptions);
      }
export type OnDeleteUserNotificationSubscriptionHookResult = ReturnType<typeof useOnDeleteUserNotificationSubscription>;
export type OnDeleteUserNotificationSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteUserNotificationSubscription>;
export const OnCreateDriverDocument = gql`
    subscription OnCreateDriver($owner: String) {
  onCreateDriver(owner: $owner) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type OnCreateDriverComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnCreateDriverSubscription, OnCreateDriverSubscriptionVariables>, 'subscription'>;

    export const OnCreateDriverComponent = (props: OnCreateDriverComponentProps) => (
      <ApolloReactComponents.Subscription<OnCreateDriverSubscription, OnCreateDriverSubscriptionVariables> subscription={OnCreateDriverDocument} {...props} />
    );
    
export type OnCreateDriverProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnCreateDriverSubscription, OnCreateDriverSubscriptionVariables> & TChildProps;
export function withOnCreateDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnCreateDriverSubscription,
  OnCreateDriverSubscriptionVariables,
  OnCreateDriverProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnCreateDriverSubscription, OnCreateDriverSubscriptionVariables, OnCreateDriverProps<TChildProps>>(OnCreateDriverDocument, {
      alias: 'onCreateDriver',
      ...operationOptions
    });
};

/**
 * __useOnCreateDriverSubscription__
 *
 * To run a query within a React component, call `useOnCreateDriverSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnCreateDriverSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnCreateDriverSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnCreateDriverSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnCreateDriverSubscription, OnCreateDriverSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnCreateDriverSubscription, OnCreateDriverSubscriptionVariables>(OnCreateDriverDocument, baseOptions);
      }
export type OnCreateDriverSubscriptionHookResult = ReturnType<typeof useOnCreateDriverSubscription>;
export type OnCreateDriverSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnCreateDriverSubscription>;
export const OnUpdateDriverDocument = gql`
    subscription OnUpdateDriver($owner: String) {
  onUpdateDriver(owner: $owner) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type OnUpdateDriverComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnUpdateDriverSubscription, OnUpdateDriverSubscriptionVariables>, 'subscription'>;

    export const OnUpdateDriverComponent = (props: OnUpdateDriverComponentProps) => (
      <ApolloReactComponents.Subscription<OnUpdateDriverSubscription, OnUpdateDriverSubscriptionVariables> subscription={OnUpdateDriverDocument} {...props} />
    );
    
export type OnUpdateDriverProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnUpdateDriverSubscription, OnUpdateDriverSubscriptionVariables> & TChildProps;
export function withOnUpdateDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnUpdateDriverSubscription,
  OnUpdateDriverSubscriptionVariables,
  OnUpdateDriverProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnUpdateDriverSubscription, OnUpdateDriverSubscriptionVariables, OnUpdateDriverProps<TChildProps>>(OnUpdateDriverDocument, {
      alias: 'onUpdateDriver',
      ...operationOptions
    });
};

/**
 * __useOnUpdateDriverSubscription__
 *
 * To run a query within a React component, call `useOnUpdateDriverSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateDriverSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateDriverSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnUpdateDriverSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnUpdateDriverSubscription, OnUpdateDriverSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnUpdateDriverSubscription, OnUpdateDriverSubscriptionVariables>(OnUpdateDriverDocument, baseOptions);
      }
export type OnUpdateDriverSubscriptionHookResult = ReturnType<typeof useOnUpdateDriverSubscription>;
export type OnUpdateDriverSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnUpdateDriverSubscription>;
export const OnDeleteDriverDocument = gql`
    subscription OnDeleteDriver($owner: String) {
  onDeleteDriver(owner: $owner) {
    id
    createdAt
    updatedAt
    name
    surname
    dob
    nationality
    idNumber
    passportNumber
    employeeNumber
    phoneNumber
    gender
    jobDescription
    department
    industry
    sport
    employmentStatus
    driverCompany {
      id
      createdAt
      updatedAt
      name
      registrationNumber
      phoneNumber
      adminEmail
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      companyGroup {
        id
        createdAt
        updatedAt
        name
        registrationNumber
        phoneNumber
        adminEmail
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        addressLine5
        owner
      }
      drivers {
        nextToken
      }
      teamCode
      legacyId
    }
    owner
  }
}
    `;
export type OnDeleteDriverComponentProps = Omit<ApolloReactComponents.SubscriptionComponentOptions<OnDeleteDriverSubscription, OnDeleteDriverSubscriptionVariables>, 'subscription'>;

    export const OnDeleteDriverComponent = (props: OnDeleteDriverComponentProps) => (
      <ApolloReactComponents.Subscription<OnDeleteDriverSubscription, OnDeleteDriverSubscriptionVariables> subscription={OnDeleteDriverDocument} {...props} />
    );
    
export type OnDeleteDriverProps<TChildProps = {}> = ApolloReactHoc.DataProps<OnDeleteDriverSubscription, OnDeleteDriverSubscriptionVariables> & TChildProps;
export function withOnDeleteDriver<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OnDeleteDriverSubscription,
  OnDeleteDriverSubscriptionVariables,
  OnDeleteDriverProps<TChildProps>>) {
    return ApolloReactHoc.withSubscription<TProps, OnDeleteDriverSubscription, OnDeleteDriverSubscriptionVariables, OnDeleteDriverProps<TChildProps>>(OnDeleteDriverDocument, {
      alias: 'onDeleteDriver',
      ...operationOptions
    });
};

/**
 * __useOnDeleteDriverSubscription__
 *
 * To run a query within a React component, call `useOnDeleteDriverSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnDeleteDriverSubscription` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnDeleteDriverSubscription({
 *   variables: {
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useOnDeleteDriverSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<OnDeleteDriverSubscription, OnDeleteDriverSubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<OnDeleteDriverSubscription, OnDeleteDriverSubscriptionVariables>(OnDeleteDriverDocument, baseOptions);
      }
export type OnDeleteDriverSubscriptionHookResult = ReturnType<typeof useOnDeleteDriverSubscription>;
export type OnDeleteDriverSubscriptionResult = ApolloReactCommon.SubscriptionResult<OnDeleteDriverSubscription>;