import { ColorProps } from 'styled-system'
import { Field, Form, Formik, FormikActions, FormikProps } from 'formik'
// import { PASSWORD_REGEX, PASSWORD_REGEX_MESSAGE } from '../../constants'
import ConnectedFormGroup from '../../components/ConnectedFormGroup'
// import * as Yup from 'yup'
import React from 'react'
import { Auth } from 'aws-amplify'
import {
  Button,
  Flex,
  Text,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/core'
import { theme } from '../../theme'

type ChangePasswordProps = ColorProps & {}

const INITIAL_VALUES = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: ''
}

type ChangePasswordFormValues = typeof INITIAL_VALUES

// const RegisterValidation = Yup.object().shape({
//   oldPassword: Yup.string()
//     .min(8, 'Password has to be 8 characters or more!')
//     .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
//     .required('A password is required'),
//   newPassword: Yup.string()
//     .min(8, 'Password has to be 8 characters or more!')
//     .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
//     .required('A password is required'),
//   confirmNewPassword: Yup.string()
//     .min(8, 'Password has to be 8 characters or more!')
//     .matches(PASSWORD_REGEX, { message: PASSWORD_REGEX_MESSAGE })
//     .required('A password is required')
// })

const ChangePassword: React.FC<ChangePasswordProps> = ({ ...rest }) => {
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleChangePassword = async (
    { oldPassword, newPassword, confirmNewPassword }: ChangePasswordFormValues,
    { setSubmitting }: FormikActions<ChangePasswordFormValues>
  ) => {
    setSubmitting(true)
    if (newPassword !== confirmNewPassword) {
      setError('New and confirmed password do not match.')
      return
    }
    try {
      setLoading(true)
      setError('')
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, oldPassword, newPassword)
      setLoading(false)
      if (onClose) {
        onClose()
      }
    } catch (err) {
      setError(err.message)
      setLoading(false)
      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <React.Fragment>
      <Button
        color={theme.colors.intent.danger}
        bg={theme.colors.background.tint5}
        fontWeight="normal"
        //@ts-ignore
        onClick={onOpen}
      >
        Change Password
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex flexDirection="column">
              <Text marginBottom="2vh">Please enter your new password and confirm it.</Text>
              <Formik
                initialValues={INITIAL_VALUES}
                // validationSchema={RegisterValidation}
                onSubmit={(values, actions) => handleChangePassword(values, actions)}
                render={({ handleSubmit }: FormikProps<ChangePasswordFormValues>) => {
                  return (
                    <Form>
                      <Field
                        name="oldPassword"
                        type="password"
                        placeholder="Current Password"
                        component={ConnectedFormGroup}
                      />
                      <Field
                        name="newPassword"
                        type="password"
                        placeholder="New Password"
                        component={ConnectedFormGroup}
                      />
                      <Field
                        name="confirmNewPassword"
                        type="password"
                        placeholder="Confirm New Password"
                        component={ConnectedFormGroup}
                      />
                      {!!error && (
                        <Text textAlign="center" fontSize="md" color="red.500" mb={2}>
                          {error}
                        </Text>
                      )}
                      <Button
                        mt={2}
                        isLoading={loading}
                        isFullWidth
                        type="submit"
                        color={theme.colors.neutral.lightest}
                        bg={theme.colors.green[550]}
                        //@ts-ignore
                        onClick={() => {
                          handleSubmit()
                          if (localStorage.getItem('newUser') === 'newAdmin') {
                            localStorage.removeItem('newUser')
                          }
                        }}
                      >
                        Save new password
                      </Button>
                    </Form>
                  )
                }}
              />

              <Flex width="100%" justifyContent="space-around">
                <Link
                  marginTop="1vh"
                  fontWeight="lighter"
                  fontSize="md"
                  color={theme.colors.green[550]}
                  onClick={onClose}
                >
                  Cancel
                </Link>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  )
}

export default ChangePassword
