// import css from '@emotion/css'
import styled from '@emotion/styled'
import DataTable from 'react-data-table-component'
import { color } from 'styled-system'
import { theme } from '../../theme'

export const StyledTable = styled(DataTable)`
  ${color}
  width: 100%;
  display: flex;
  flex-direction: column;
  .rdt_TableCell {
    color: ${theme.colors.text.muted};
  }
  .rdt_TableCol_Sortable {
    font-size: 14px;
    color: black;
  }
  .rdt_TableHeadRow {
    background-color: ${theme.colors.neutral.lightest};
  }
  .rdt_TableCell {
    color: ${theme.colors.text.grey};
  }
  .rdt_TableCol_Sortable {
    font-size: 14px;
    color: black;
  }
  .rdt_TableHeadRow {
    background-color: ${theme.colors.neutral.lightest};
  }
`

// .rdt_Table {
//   outline: 1px solid red;
// }
