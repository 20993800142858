import React, { Suspense } from 'react'
import { Home, Bell, Search, Truck, User, Activity, Unlock } from 'react-feather'
import { Redirect, Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader, SideBar, Header, Footer } from '../components'
import Loader from '../components/FillLoader'
import PageNotFound from '../containers/PageNotFound'
import { useAuthentication } from '../hooks'
import images from '../theme/images'
import { PUBLIC_ROUTES, ADMIN_ROUTES, SUPER_ADMIN_ROUTES } from './routes'
import { theme } from '../theme'

interface RouteType extends RouteProps {
  component: React.ComponentType<any>
}

const NAV_ITEMS = [
  {
    to: '/dashboard',
    title: 'Dashboard',
    icon: <Home strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/driver-management',
    title: 'Driver Management',
    icon: <Truck strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/user-management',
    title: 'User Management',
    icon: <Truck strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/eyegym-reports',
    title: 'EyeGym Reports',
    icon: <Activity strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/logout',
    title: 'logout',
    icon: <Unlock strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  }
]

const SUPER_ADMIN_NAV_ITEMS = [
  {
    to: '/dashboard',
    title: 'Dashboard',
    icon: <Home strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/admin-management',
    title: 'Admin Management',
    icon: <User strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/driver-management',
    title: 'Driver Management',
    icon: <Truck strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/user-management',
    title: 'User Management',
    icon: <Truck strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/eyegym-reports',
    title: 'EyeGym Reports',
    icon: <Activity strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  },
  {
    to: '/logout',
    title: 'Logout',
    icon: <Unlock strokeWidth={1.5} size={20} color="white" className="sidebar-menu-icon" />
  }
]

const TOP_NAV_ITEMS = [
  {
    to: '/admin-management',
    title: 'Search',
    icon: <Search strokeWidth={1.5} size={20} color="grey" className="sidebar-menu-icon" />
  },
  {
    to: '/dashboard',
    title: 'Notifications',
    icon: <Bell strokeWidth={1.5} size={20} color="grey" className="sidebar-menu-icon" />
  },
  {
    to: '/profile',
    title: 'Profile',
    icon: <User strokeWidth={1.5} size={20} color="grey" />
  }
]

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { authenticating, isAuthenticated } = useAuthentication()
  const { getUser } = useAuthentication()
  const { role } = getUser()

  return !authenticating ? (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <SideBar
            mode="push"
            color="white"
            bg="gray.800"
            hoverColor={theme.colors.green[550]}
            navItems={role === 'SUPER_ADMIN' ? SUPER_ADMIN_NAV_ITEMS : NAV_ITEMS}
            accentColor={theme.colors.green[550]}
          >
            <Header bg="white" navItems={TOP_NAV_ITEMS}></Header>
            <React.Suspense fallback={<FillLoader bg="gray.50" />}>
              <Component {...props} {...rest} />
            </React.Suspense>
            <Footer />
          </SideBar>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  ) : (
    <Loader />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
)

const Navigation = () => {
  const { getUser } = useAuthentication()
  const { role } = getUser()

  return (
    <Router>
      <Suspense fallback={<FillLoader backgroundImage={images.landingBackground} />}>
        <Switch>
          {PUBLIC_ROUTES.map((route) => {
            return <PublicRoute key={route.path} {...route} />
          })}
          {role === 'ADMIN'
            ? ADMIN_ROUTES.map((route) => {
                return <PrivateRoute key={route.path} {...route} />
              })
            : null}
          {role === 'SUPER_ADMIN'
            ? SUPER_ADMIN_ROUTES.map((route) => {
                return <PrivateRoute key={route.path} {...route} />
              })
            : null}
          <Route render={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Navigation
