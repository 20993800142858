import React from 'react'
import { ColorProps } from 'styled-system'
import { StyledInput } from './styles'

export type TextFilterProps = ColorProps & {
  onFilter: any
  color?: string
  placeholder: string
}

const TextFilter: React.FC<TextFilterProps> = ({ onFilter, placeholder }) => (
  <StyledInput
    id={'search-' + placeholder.replace(/ /g, '-')}
    onChange={(e: any) => onFilter(e.target.value)}
    placeholder={placeholder}
    type="search"
    role="search"
    focusBorderColor="green"
  />
)

export default TextFilter
