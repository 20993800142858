import { Text, Image } from '@chakra-ui/core'
import { ColorProps, color } from 'styled-system'
import styled from '@emotion/styled'
import images from '../../theme/images'
import React from 'react'
import { theme } from '../../theme'

type FooterWrapperProps = ColorProps & {
  color?: string
}

type FooterProps = ColorProps & {
  color?: string
}

export const FooterWrapper = styled.div<FooterWrapperProps>`
  ${color};
  display: flex;
  max-width: 100%;
  min-height: 10vh;
  max-height: 10vh;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  opacity: 100%;
  background-color: ${theme.colors.solid.white};
  border-top-width: 0.5vh;
  border-color: ${theme.colors.background.tint5};
`

export const LogoContainer = styled.div<any>`
  position: fixed;
  right: 4vw;
`

const Footer: React.FC<FooterProps> = ({ color }) => {
  return (
    <FooterWrapper color={color}>
      <Text fontSize="sm" color="gray.500">
        {' '}
        Copyright &copy; {new Date().getFullYear()} VPJ{' '}
      </Text>
      <LogoContainer>
        <Image height="40px" src={images.driveribLogo} alt="Driver Bureau Logo" />
      </LogoContainer>
    </FooterWrapper>
  )
}

export default Footer
