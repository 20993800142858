import styled from '@emotion/styled'
import * as React from 'react'
import {
  alignItems,
  color,
  flex,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  maxHeight,
  space,
  width
} from 'styled-system'
import { StyledSystemProps } from '../typings/StyledSystemProps'

type Props = StyledSystemProps & {
  color?: string
  backgroundImage?: string
  backgroundSize?: string
}

const StyledPageWrap = styled.div`
  ${flex};
  ${space};
  ${color};
  ${width};
  ${height};
  ${flexWrap};
  ${maxHeight};
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  display: flex;
  box-sizing: border-box;
  background-size: ${(props: Props) => props.backgroundSize};
  background-image: url(${(props: Props) => props.backgroundImage});
`

const PageWrap: React.FC<Props> = ({ children, ...rest }) => {
  return <StyledPageWrap {...rest}>{children}</StyledPageWrap>
}

PageWrap.defaultProps = {
  p: 4,
  flex: 0.15,
  bg: 'gray.50',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  backgroundSize: 'cover',
  padding: '0px',
  marginBottom: '5vh'
}

export default PageWrap
