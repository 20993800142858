import { FunctionComponent, lazy, LazyExoticComponent } from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'

const AdminDashboard = lazy(() => import('../containers/Dashboard'))
const SuperAdminDashboard = lazy(() => import('../containers/Dashboard'))
const AdminManagement = lazy(() => import('../containers/AdminManagement'))
const DriverManagement = lazy(() => import('../containers/DriverManagement'))
const EyegymRawReports = lazy(() => import('../containers/EyegymRawReports'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const Login = lazy(() => import('../containers/Authentication/Login'))
const Profile = lazy(() => import('../containers/Profile'))
const Register = lazy(() => import('../containers/Authentication/Register'))
const ResetPassword = lazy(() => import('../containers/Authentication/ResetPassword'))
const VerifyEmail = lazy(() => import('../containers/Authentication/VerifyEmail'))
const Logout = lazy(() => import('../containers/Authentication/Logout'))

const UserManagement = lazy(() => import('../containers/UserManagement'))

export type PrivateRouteObject = {
  exact: boolean
  path: string
  positionInNav: number
  breadcrumb: string
  component: LazyExoticComponent<FunctionComponent<RouteComponentProps<{}, StaticContext, any>>>
  title: string
}

const ADMIN_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/dashboard',
    positionInNav: 1,
    breadcrumb: 'Dashboard',
    component: AdminDashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/profile',
    positionInNav: 2,
    breadcrumb: 'Profile',
    component: Profile,
    title: 'Profile'
  },
  {
    exact: true,
    path: '/driver-management',
    positionInNav: 3,
    breadcrumb: 'Driver Management',
    component: DriverManagement,
    title: 'Driver Management'
  },
  {
    exact: true,
    path: '/user-management',
    positionInNav: 4,
    breadcrumb: 'User Management',
    component: UserManagement,
    title: 'User Management'
  },
  {
    exact: true,
    path: '/eyegym-reports',
    positionInNav: 5,
    breadcrumb: 'EyeGym Reports',
    component: EyegymRawReports,
    title: 'EyeGym Reports'
  },
  {
    exact: true,
    path: '/logout',
    positionInNav: 5,
    breadcrumb: 'Logout',
    component: Logout,
    title: 'Logout'
  }
]

const SUPER_ADMIN_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/dashboard',
    positionInNav: 1,
    breadcrumb: 'Dashboard',
    component: SuperAdminDashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/admin-management',
    positionInNav: 2,
    breadcrumb: 'Admin Management',
    component: AdminManagement,
    title: 'Admin Management'
  },
  {
    exact: true,
    path: '/profile',
    positionInNav: 3,
    breadcrumb: 'Profile',
    component: Profile,
    title: 'Profile'
  },
  {
    exact: true,
    path: '/driver-management',
    positionInNav: 4,
    breadcrumb: 'Driver Management',
    component: DriverManagement,
    title: 'Driver Management'
  },
  {
    exact: true,
    path: '/eyegym-reports',
    positionInNav: 5,
    breadcrumb: 'EyeGym Reports',
    component: EyegymRawReports,
    title: 'EyeGym Reports'
  },
  {
    exact: true,
    path: '/user-management',
    positionInNav: 6,
    breadcrumb: 'User Management',
    component: UserManagement,
    title: 'User Management'
  },
  {
    exact: true,
    path: '/logout',
    positionInNav: 7,
    breadcrumb: 'Logout',
    component: Logout,
    title: 'Logout'
  }
]

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/dashboard',
    positionInNav: 1,
    breadcrumb: 'Dashboard',
    component: AdminDashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/admin-management',
    positionInNav: 2,
    breadcrumb: 'Admin Management',
    component: AdminManagement,
    title: 'Admin Management'
  },
  {
    exact: true,
    path: '/profile',
    positionInNav: 3,
    breadcrumb: 'Profile',
    component: Profile,
    title: 'Profile'
  },
  {
    exact: true,
    path: '/logout',
    positionInNav: 5,
    breadcrumb: 'Logout',
    component: Logout,
    title: 'Logout'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    path: '/',
    component: Login,
    title: 'Login'
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPassword,
    title: 'Forgot Password'
  },
  {
    exact: true,
    path: '/reset-password',
    component: ResetPassword,
    title: 'Reset Password'
  },
  {
    exact: true,
    path: '/register',
    component: Register,
    title: 'Register'
  },
  {
    exact: true,
    path: '/verify-email',
    component: VerifyEmail,
    title: 'Verify Email'
  },
  {
    exact: true,
    path: '/logout',
    positionInNav: 5,
    breadcrumb: 'Logout',
    component: Logout,
    title: 'Logout'
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES, ADMIN_ROUTES, SUPER_ADMIN_ROUTES }
