import React from 'react'
import { RouteComponentProps } from 'react-router'
import { H1, H4 } from '../../components/Typography'
import { PageWrap } from '../../layouts'
import images from '../../theme/images'

const PageNotFound: React.FC<RouteComponentProps> = () => (
  <PageWrap
    height="100vh"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    backgroundImage={images.landingBackground}
    backgroundSize="80%"
  >
    <H1>Damn it</H1>
    <H4>The thing you are looking for is not a thing.</H4>
    <H4>Maybe look for another thing?</H4>
  </PageWrap>
)

export default PageNotFound
