import { CardProps } from '../Card'
import Row from '../ResponsiveGrid/Row'
import Col from '../ResponsiveGrid/Col'
import { Text, Box } from '@chakra-ui/core'
import React from 'react'

export type SubHeaderProps = CardProps & {
  heading: string
  description: string
}

const SubHeader: React.FC<SubHeaderProps> = ({
  onClick,
  heading,
  description,
  children,
  ...rest
}) => (
  <Box onClick={onClick} height="15vh" {...rest}>
    <Row>
      <Col sm={12} md={9}>
        <Box
          mt="1"
          // fontWeight="semibold"
          as="h1"
        >
          <Text marginLeft="10px" fontSize="2xl">
            {heading}
          </Text>
        </Box>
        <Box>
          <Text marginLeft="10px" fontSize="lg" color="gray.500">
            {description}
          </Text>
        </Box>
      </Col>
      <Col sm={12} md={3}>
        {children}
      </Col>
    </Row>
  </Box>
)

SubHeader.defaultProps = {
  p: 6,
  bg: 'white',
  width: '100%',
  height: '15vh',
  flexDirection: 'column',
  marginBottom: '3vh'
  // marginTop: '7vh'
}

export default SubHeader
