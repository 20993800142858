import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { Auth } from 'aws-amplify'
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import appSyncConfig from './aws-exports'

const url = appSyncConfig.aws_appsync_graphqlEndpoint
const region = appSyncConfig.aws_appsync_region
const auth = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  // jwtToken: async () =>
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3ZDhjYTUyOC00OTMxLTQyNTQtOTI3My1lYTVlZTg1M2YyNzEiLCJjb2duaXRvOmdyb3VwcyI6WyJTVVBFUl9BRE1JTiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWxnb3JpdGhtIjoiSFMyNTYiLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tL3VzLWVhc3QtMV9mYWtlX2lkcCIsInBob25lX251bWJlcl92ZXJpZmllZCI6dHJ1ZSwiY29nbml0bzp1c2VybmFtZSI6InVzZXIxIiwiY29nbml0bzpyb2xlcyI6W10sImF1ZCI6IjJoaWZhMDk2YjNhMjRtdm0zcGhza3VhcWkzIiwiZXZlbnRfaWQiOiIxOGY0MDY3ZS05OTg1LTRlYWUtOWYzMy1mNDVmNDk1NDcwZDAiLCJ0b2tlbl91c2UiOiJpZCIsInBob25lX251bWJlciI6IisxMjA2MjA2MjAxNiIsImV4cCI6MTU3MDgzMzU4NSwiZW1haWwiOiJzYW52aXJAc292LmVjaCIsImF1dGhfdGltZSI6MTU3MDc5MDM4NSwiaWF0IjoxNTcwNzkwMzg1fQ.tYM_tGfLsy8OYAYL1q7rWXnY7DA1EenofI3gT4KB2ts'
}

const link = ApolloLink.from([
  // @ts-ignore - complains about auth type
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink(url, createHttpLink({ uri: url }))
])

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

export default client
