import React from 'react'
import { PageWrap } from '../../layouts'
import { H3 } from '../Typography'

const FallbackErrorMessage: React.FC = () => (
  <PageWrap>
    <H3>An error has occurred.</H3>
  </PageWrap>
)

export default FallbackErrorMessage
