import { ColorProps } from 'styled-system'
import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/core'

import { theme } from '../../theme'

type AlertProps = ColorProps & {
  color?: string
  label?: string
  heading?: string
  body?: string
  cancel?: string
  action?: string
  onCancelClick?: () => void
  onActionClick?: () => void
  hideButton?: boolean
  loading?: boolean
}

/**
 * A custom alert dialog
 * @param label - The text to put in the button which opens the alert
 * @param heading - The alert dialog heading
 * @param body - The alert dialog body
 * @param cancel - The text to put in the cancel button
 * @param action - The text to put in the action button
 * @param onCancelClick - The function to call on cancel
 * @param onActionClick - The function to call on action
 */
const Alert: React.FC<AlertProps> = ({
  label,
  heading,
  body,
  cancel,
  action,
  onCancelClick,
  onActionClick,
  hideButton,
  children,
  loading,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState()
  const onClose = () => setIsOpen(false)
  const cancelRef: any = React.useRef()

  return (
    <>
      {!hideButton ? (
        <Button
          onClick={() => setIsOpen(true)}
          color={theme.colors.intent.danger}
          backgroundColor={theme.colors.solid.white}
          fontWeight="thin"
        >
          {label}
        </Button>
      ) : null}

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {heading}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          {children}

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
              {cancel}
            </Button>
            <Button
              isLoading={loading}
              variantColor="red"
              onClick={async () => {
                if (onActionClick) {
                  await onActionClick()
                  setIsOpen(false)
                } else {
                  setIsOpen(false)
                }
              }}
              ml={3}
            >
              {action}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

Alert.defaultProps = {
  heading: 'Alert',
  body: 'Are you sure you want to do this?',
  cancel: 'Cancel',
  action: 'Continue',
  onCancelClick: () => false,
  onActionClick: () => false,
  hideButton: false,
  loading: false
}

export default Alert
