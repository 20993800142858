import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { ColorProps } from 'styled-system'
import { PageWrap } from '../../layouts'
import { AppContext } from '../../contexts/ApplicationProvider.context'
import HamburgerIcon from '../SideBar/icon'
import { useMediaQuery } from 'react-responsive'

import { HeaderWrapper, StyledLink, NavItemWrapper, NavListWrapper } from './styles'

type NavItems = {
  to: string
  title: string
  icon: React.ReactNode
  subMenu?: Array<{ to: string; title: string }>
}

type HeaderProps = ColorProps & {
  color?: string
  navItems: NavItems[]
}

type HeaderLinkProps = ColorProps & {
  to: string
  title: string
  color?: string
  icon: React.ReactNode
}

const HeaderLink: React.FC<HeaderLinkProps> = ({ to, title, color, icon }) => (
  <StyledLink
    px={4}
    as={NavLink}
    to={to}
    activeClassName="active-nav-link"
    className={'nav-link-text-hidden'}
  >
    <NavItemWrapper className="sidebar-nav-item-wrapper">
      {icon}
      {/* <Text ml={4}>{title}</Text> */}
    </NavItemWrapper>
  </StyledLink>
)

const Header: React.FC<HeaderProps> = ({ children, navItems, color, bg }) => {
  const { drawerOpen, toggleDrawer } = React.useContext(AppContext)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  return (
    <PageWrap p={0} height="7vh">
      <HeaderWrapper color={color} bg={bg}>
        {isTabletOrMobile && (
          <HamburgerIcon
            ml={5}
            open={false}
            onClick={toggleDrawer}
            bg={drawerOpen ? 'gray.100' : 'gray.800'}
          />
        )}
        <NavListWrapper
          color={color}
          bg={bg}
          pr={isTabletOrMobile ? '20px' : drawerOpen ? '350px' : '160px'}
        >
          {navItems.map((props) => (
            <HeaderLink color={color} bg={bg} key={props.title} {...props} />
          ))}
        </NavListWrapper>
      </HeaderWrapper>
    </PageWrap>
  )
}

export default Header

Header.defaultProps = {
  navItems: []
}
