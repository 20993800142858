import React, { useEffect } from 'react'
import {
  User,
  UserRole,
  UserStatus,
  useGetUserQuery,
  useUpdateUserMutation
} from '../../generated/graphql'
import { useToast, Flex, Text, CheckboxGroup, Checkbox } from '@chakra-ui/core'
import { Row, Col } from 'react-flexbox-grid'
import { Card, CardHeader, CardFooter, FillLoader, Container } from '..'
import { Formik, FormikProps, Form, Field } from 'formik'
import { StyledField } from './styles'
import ConnectedFormGroup from '../ConnectedFormGroup'
import ProfilePicture from '../ProfilePicture'
import { theme } from '../../theme'
import { RouteComponentProps } from 'react-router'
import ConnectedSelect from '../ConnectedSelect'
import { LEGACY_COMPANY_LOOKUP } from '../../constants'
import { useAppContext } from '../../contexts/ApplicationProvider.context'
import ConnectedPhoneNumber from '../connectedPhoneNumber'

const INITIAL_VALUES = {
  id: '',
  createdAt: '',
  updatedAt: '',
  name: '',
  surname: '',
  email: '',
  phoneNumber: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  addressLine4: '',
  addressLine5: '',
  role: UserRole.Admin,
  status: UserStatus.Active
}

type FormValues = User

type ProfileProps = RouteComponentProps & {
  renderHeader: (role: UserRole, name: string, surname: string) => React.ReactFragment
  renderFooter: (
    handleSubmit: () => void,
    loading: boolean,
    editable: boolean,
    setEditable: () => void,
    goBack: () => void
  ) => React.ReactFragment
  userId: string
  isLoggedInUser: boolean
}

const Profile: React.FC<ProfileProps> = ({
  renderHeader,
  renderFooter,
  userId,
  children,
  history,
  isLoggedInUser
}) => {
  const { refetch, error, loading, data } = useGetUserQuery({ variables: { id: userId } })
  const [updateUserMutation] = useUpdateUserMutation()

  const [editable, setEditable] = React.useState(false)

  const [isAdmin, setIsAdmin] = React.useState(
    data && data.getUser && data.getUser.role === 'ADMIN'
  )

  const { userProfile } = useAppContext()
  const toast = useToast()

  useEffect(() => {
    if (!editable) {
      setIsAdmin(data && data.getUser && data.getUser.role === 'ADMIN')
    }
  }, [editable, data])

  return (
    <Row>
      <Col sm={12} md={12}>
        {error && <div>{error}</div>}
        {loading && <FillLoader bg="gray.50" />}
        {data && data.getUser && (
          <Formik
            initialValues={data.getUser || INITIAL_VALUES}
            // validationSchema={getProfileFormValidation()}
            onSubmit={async (values, { setSubmitting, setError }) => {
              try {
                if (!isLoggedInUser) {
                  // @ts-ignore
                  if (values.companies) {
                    // @ts-ignore
                    values.dibCompanies = values.companies.map(
                      (company: { value: any }) => company.value
                    )
                  }
                }
                // delete values.reviews
                // @ts-ignore
                delete values.companies
                delete values.__typename
                delete values.companyGroups
                delete values.createdAt
                delete values.notifications
                delete values.owner

                values.role = isAdmin ? UserRole.Admin : UserRole.SuperAdmin

                await updateUserMutation({
                  variables: { input: { ...values, id: userId } }
                })
                refetch()
                setSubmitting(false)
                toast({
                  duration: 6000,
                  isClosable: true,
                  status: 'success',
                  title: 'Success!.',
                  description: 'Your profile has been updated successfully.',
                  position: 'top-right'
                })
                setEditable(false)
              } catch (error) {
                setError(error.message)
                setSubmitting(false)
              }
            }}
            render={({ handleSubmit, error, isSubmitting }: FormikProps<FormValues>) => {
              return (
                <Card p={4}>
                  <CardHeader flexDirection="row">
                    {data &&
                      data.getUser &&
                      renderHeader(data.getUser.role, data.getUser.name, data.getUser.surname)}
                  </CardHeader>
                  <React.Fragment>
                    <Flex flexDirection="column" py={4}>
                      <Container style={{ width: '100%' }} fluid={true}>
                        <Row>
                          <Col sm={12} md={8}>
                            <Form>
                              <Row>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="name"
                                    label="Name"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="surname"
                                    label="Surname"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="email"
                                    type="email"
                                    label="Email"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="phoneNumber"
                                    label="Phone Number"
                                    component={ConnectedPhoneNumber}
                                    disabled={!editable}
                                    focusBorderColor={theme.colors.green}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="addressLine5"
                                    label="Country"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="addressLine1"
                                    label="Street Name"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="addressLine2"
                                    label="Suburb"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="addressLine3"
                                    label="City"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6}>
                                  <StyledField
                                    name="addressLine4"
                                    label="Postal Code"
                                    component={ConnectedFormGroup}
                                    disabled={!editable}
                                  />
                                </Col>

                                {/* If the profile component is being viewed by the logged in user 
                                    the user is viewing their own profile.
                                    If the component is being viewed by a different user then it is an admin viewing
                                    another user's profile
                                */}
                                {isLoggedInUser ? (
                                  <Col sm={12} md={6}>
                                    <StyledField
                                      name="roleType"
                                      label="Assigned Role Type"
                                      component={ConnectedFormGroup}
                                      disabled={!editable}
                                    />
                                  </Col>
                                ) : (
                                  <Col sm={12} md={6}>
                                    <Text mb={3}>User Type</Text>
                                    <CheckboxGroup
                                      variantColor="green"
                                      spacing="1vh"
                                      name="UserType"
                                      onChange={() => {
                                        if (editable) {
                                          setIsAdmin(!isAdmin)
                                        }
                                      }}
                                      value={isAdmin ? ['ADMIN'] : ['SUPER_ADMIN']}
                                    >
                                      <Checkbox value="ADMIN">Admin</Checkbox>
                                      <Checkbox value="SUPER_ADMIN">Super Admin</Checkbox>
                                    </CheckboxGroup>
                                  </Col>
                                )}
                              </Row>
                              {!!error && (
                                <Text
                                  textAlign="center"
                                  fontSize="md"
                                  color="red.500"
                                  fontWeight="lighter"
                                  mb={2}
                                >
                                  {error}
                                </Text>
                              )}
                            </Form>
                          </Col>

                          <Col sm={12} md={4}>
                            <Flex flexDirection="column" p={2}>
                              <Text marginBottom="1vh" color="text.dark" fontSize="md">
                                {'Profile Picture'}
                              </Text>
                              <Card mb={4} justifyContent="center">
                                <ProfilePicture width="50%" name="profile-picture" />
                              </Card>
                            </Flex>

                            {!isLoggedInUser && (
                              <Row>
                                <Field
                                  name="companies"
                                  label="Companies"
                                  disabled={!editable}
                                  isMulti
                                  options={
                                    userProfile && userProfile.dibCompanies
                                      ? userProfile.dibCompanies.map((companyId: any) => {
                                          return {
                                            selected: true,
                                            value: companyId,
                                            label: LEGACY_COMPANY_LOOKUP[companyId.toString()]
                                          }
                                        })
                                      : []
                                  }
                                  defaultValue={
                                    data &&
                                    data.getUser &&
                                    data.getUser.dibCompanies &&
                                    data.getUser.dibCompanies.map((companyId: any) => {
                                      return {
                                        value: companyId,
                                        label: LEGACY_COMPANY_LOOKUP[companyId.toString()]
                                      }
                                    })
                                  }
                                  component={ConnectedSelect}
                                  focusBorderColor={theme.colors.green[550]}
                                />
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </Container>
                    </Flex>
                  </React.Fragment>
                  <CardFooter>
                    {renderFooter(
                      handleSubmit,
                      loading,
                      editable,
                      () => {
                        setEditable(!editable)
                      },
                      () => {
                        history.goBack()
                      }
                    )}
                  </CardFooter>
                </Card>
              )
            }}
          />
        )}
        {/* </GetUserComponent> */}
      </Col>
    </Row>
  )
}

export default Profile
