import { Flex } from '@chakra-ui/core'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import withBreadcrumbs, { BreadcrumbsProps } from 'react-router-breadcrumbs-hoc'
import { Link } from 'react-router-dom'
import { PRIVATE_ROUTES } from '../../navigation/routes'
import { H5 } from '../Typography'

const Breadcrumbs: React.FC<RouteComponentProps & { breadcrumbs: BreadcrumbsProps<{}>[] }> = ({
  breadcrumbs
}) => {
  const slicedBreadcrumbs = breadcrumbs.slice(1)
  return (
    <Flex flexDirection="row">
      {slicedBreadcrumbs.map(({ breadcrumb, match }, index) => (
        <React.Fragment key={match.url}>
          <Link to={match.url || ''}>
            <H5 mb={0}>{breadcrumb}</H5>
          </Link>
          {index < slicedBreadcrumbs.length - 1 && ' > '}
        </React.Fragment>
      ))}
    </Flex>
  )
}

export default withBreadcrumbs<{}>(PRIVATE_ROUTES)(Breadcrumbs)
