import * as Yup from 'yup'

export const getCreateUserFormValidation = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email("Please make sure that you've entered the correct email address")
      .required('An email address is required'),
    name: Yup.string()
      .required('A first name is required')
      .nullable(),
    surname: Yup.string()
      .required('A surname is required')
      .nullable(),
    phoneNumber: Yup.string()
      .min(10, 'Phone number must be 10 numbers or more')
      .required('A phone number is required')
      .nullable(),
    companies: Yup.array().required('Please select companies which the user will have access to')
  })
}
