import { Flex } from '@chakra-ui/core'
import { FlexProps } from '@chakra-ui/core/dist/Flex'
import * as React from 'react'

type CardHeaderProps = FlexProps

const CardHeader: React.FC<CardHeaderProps> = ({ children, ...rest }) => {
  return (
    // @ts-ignore - incorrect typings for flex component, borderBottomWidth does exist
    <Flex borderBottomWidth="0px" overflowX="hidden" overflowY="hidden" {...rest}>
      {children}
    </Flex>
  )
}

export default CardHeader

CardHeader.defaultProps = {
  p: 4,
  flexDirection: 'column',
  alignItems: 'center'
}
