import styled from '@emotion/styled'
import { color, ColorProps } from 'styled-system'
import { theme } from '../../theme'

type HeaderWrapperProps = ColorProps & {
  color?: string
}

type NavListWrapperProps = ColorProps & {
  color?: string
  pr: string
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  ${color};
  display: flex;
  max-width: 100%;
  min-height: 7vh;
  max-height: 7vh;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px 0.4vh ${theme.colors.background.tint4};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: fixed;
  width: 100%;
  & .sidebar-menu-icon {
    font-size: 21px;
  }
  & .nav-link-text-hidden > div > span {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

export const NavItemWrapper = styled.div<any>`
  flex: 1;
  height: 7vh;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  &:hover .sidebar-tooltip {
    opacity: 1;
    width: auto;
    padding: 10px;
    visibility: visible;
    transform: translateX(0);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

export const NavListWrapper = styled.div<NavListWrapperProps>`
  flex: 1;
  height: 7vh;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  justify-content: flex-end;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  padding-right: ${(props: { pr: string }) => props.pr};
`

export const StyledLink = styled.div<any>`
  ${color};
  height: 50px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  justify-content: flex-start;
  &.active-nav-link > div svg {
    stroke: ${theme.colors.green[550]};
  }
  & > div span {
    color: ${theme.colors.neutral.light};
  }
  &:hover > div svg {
    stroke: ${theme.colors.green[550]};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &:hover span {
    color: ${theme.colors.neutral.light};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &:hover {
    background: ${theme.colors.opacity.transparentWhite};
  }
`
