import gql from 'graphql-tag'

export default gql`
  query getUser($id: ID!) {
    getUser(id: $id) {
      id
      createdAt
      updatedAt
      email
      name
      surname
      phoneNumber
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      addressLine5
      role
      roleType
      status
      dibCompanies
      companyGroups {
        items {
          CompanyGroup {
            id
            name
          }
        }
      }
    }
  }
`
