// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8d570317-70fc-418c-8b83-c549215af681",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_zTHV4it0C",
    "aws_user_pools_web_client_id": "118muuhp4uch2pm0m6vq20vm7j",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://5wagdskquvat3cz3n2gvo657vi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "driver-bureau-web--hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1gsq07kh3wpso.cloudfront.net",
    "aws_user_files_s3_bucket": "driverbureauwebefb4461104364f2fa762b15b248a9709-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
