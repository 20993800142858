import styled from '@emotion/styled'
import { theme } from '../../theme'

export const RedIndicator = styled.div`
  background-color: ${theme.colors.intent.danger};
  border-radius: 50%;
  height: 7px;
  width: 7px;
`

export const GreenIndicator = styled.div`
  background-color: ${theme.colors.green[550]};
  border-radius: 50%;
  height: 7px;
  width: 7px;
`

export const OrangeIndicator = styled.div`
  background-color: ${theme.colors.intent.warning};
  border-radius: 50%;
  height: 7px;
  width: 7px;
`
export const StatusIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5vw;
`
