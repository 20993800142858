import { Box } from '@chakra-ui/core'
import { BoxProps } from '@chakra-ui/core/dist/Box'
import React from 'react'

export type CardProps = BoxProps & {
  onClick?: () => void
  color?: string
}

const Card: React.FC<CardProps> = ({ onClick, children, ...rest }) => (
  <Box onClick={onClick} {...rest}>
    {children}
  </Box>
)

Card.defaultProps = {
  p: 6,
  bg: 'white',
  shadow: 'md',
  width: 'auto',
  rounded: 'md',
  onClick: () => false,
  flexDirection: 'column'
}

export default Card
