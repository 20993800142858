import styled from '@emotion/styled'
import { Field } from 'formik'
import { theme } from '../../theme'

export const StyledField = styled(Field)`
  color: ${theme.colors.solid.black};
  opacity: 0.6;
  &:disabled {
    cursor: text !important;
    opacity: 1;
    color: ${theme.colors.green[550]};
    border: 0px;
  }
`
