import React from 'react'
import { TableProps } from '../Table'
import { StyledTable } from '../Table/styles'
import TextFilter from '../TextFilter'
import { CreateUser } from '../../components'
import { Flex } from '@chakra-ui/core'
import { useAppContext } from '../../contexts/ApplicationProvider.context'

type SmartTableProps = TableProps & {
  filterSelector: string[]
  filterPlaceholder: string
  data: any[]
  showCreate: boolean
}

/**
 * Table component with a text filter
 * @param filterPlaceholder - The string in the text input field
 * @param filterSelector - The key in data objects to filter by
 * @param data - The unfiltered array of json data to display
 */

const SmartTable: React.FC<SmartTableProps> = ({
  filterPlaceholder,
  filterSelector,
  data,
  color,
  title,
  columns,
  defaultSortField,
  bg,
  showCreate
}) => {
  const { userProfile } = useAppContext()
  const [filterText, setFilterText] = React.useState('')
  const filteredItems = data.filter((item) =>
    filterSelector.some(
      (selector) => !item[selector] || item[selector].toLowerCase().includes(filterText)
    )
  )
  const subHeaderComponentMemo = React.useMemo(
    () => (
      <TextFilter
        placeholder={filterPlaceholder}
        onFilter={(value: any) => setFilterText(value.toLowerCase())}
      />
    ),
    [filterPlaceholder]
  )

  return (
    <StyledTable
      color={color}
      title={title}
      data={filteredItems}
      columns={columns}
      defaultSortField={defaultSortField}
      bg={bg}
      subHeader
      subHeaderAlign="left"
      subHeaderComponent={
        showCreate
          ? [
              subHeaderComponentMemo,
              <Flex width="50vw"></Flex>,
              <CreateUser
                companyGroups={
                  userProfile && userProfile.companyGroups && userProfile.companyGroups.items
                }
              ></CreateUser>
            ]
          : [subHeaderComponentMemo, <Flex width="50vw"></Flex>]
      }
      pagination
    />
  )
}

export default SmartTable
