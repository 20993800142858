import React from 'react'
import { Text } from '@chakra-ui/core'
import { RedIndicator, GreenIndicator, OrangeIndicator, StatusIndicatorWrapper } from './styles'

export type StatusIndicatorProps = {
  status: string
  mapping: string[]
}

/**
 * Status dot indicator component
 * @param status - the actual status
 * @param mapping - an array specifying the component to return for each status where [green, orange, red]
 */

const StatusIndicators: React.FC<StatusIndicatorProps> = ({ status, mapping }) => {
  switch (status) {
    case mapping[0]: {
      return (
        <StatusIndicatorWrapper>
          <GreenIndicator />
          <Text>{status}</Text>
        </StatusIndicatorWrapper>
      )
    }
    case mapping[1]: {
      return (
        <StatusIndicatorWrapper>
          <OrangeIndicator />
          <Text>{status}</Text>
        </StatusIndicatorWrapper>
      )
    }
    case mapping[2]: {
      return (
        <StatusIndicatorWrapper>
          <RedIndicator />
          <Text>{status}</Text>
        </StatusIndicatorWrapper>
      )
    }
    default: {
      return (
        <StatusIndicatorWrapper>
          <GreenIndicator />
          <Text>{status}</Text>
        </StatusIndicatorWrapper>
      )
    }
  }
}

export default StatusIndicators
