// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

// Bugsnag
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || ''

/**
 * Password regex for alphanumeric password with special characters
 * ^                  The password string will start this way
 * (?=.*[a-z])        The string must contain at least 1 lowercase alphabetical character
 * (?=.*[A-Z])        The string must contain at least 1 uppercase alphabetical character
 * (?=.*[0-9])        The string must contain at least 1 numeric character
 * @see https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
 */
export const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const LEGACY_COMPANY_LOOKUP: any = {
  '12': 'SASOL EEFT',
  '13': 'SASOL BOESJESSPRUIT',
  '14': 'SASOL SYFERFONTEIN',
  '15': 'SASOL SIGMA',
  '16': 'SASOL BRANDSPRUIT',
  '17': 'SASOL LMDD',
  '18': 'SASOL MIDDELBULT',
  '19': 'SASOL MINING ACADEMY',
  '20': 'SASOL TWISDRAAI',
  '21': 'Safe and Eco Driving',
  '22': 'SASOL CENTRAL WORKSHOP',
  '23': 'SASOL SCS / PLANT',
  '24': 'SASOL MANAGEMENT',
  '25': 'MOULTAR COUNTRY COURIERS',
  '26': 'Prinsloo',
  '27': 'Test inc',
  '28': 'Mining Test Company',
  '33': 'Two Mountains Holding',
  '34': 'Sasol SMRD',
  '35': 'Hulamin Management',
  '36': 'SASOL Permanents Induction',
  '37': 'SASOL Contractors Induction',
  '50': 'Stefanutti Stocks Mining Services',
  '51': 'Euro Steel'
}
