import { useToast } from '@chakra-ui/core'
import * as React from 'react'
import { useServiceWorker } from './hooks/useServiceWorker'
import Navigation from './navigation'

const App: React.FC = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()
  const toast = useToast()

  React.useEffect(() => {
    if (isUpdateAvailable) {
      toast({
        duration: 6000,
        isClosable: true,
        status: 'success',
        title: 'Success!.',
        description: 'Please click to update the website and get the latest changes..',
        position: 'top-right',
        onClose: updateAssets
      })
    }
    // eslint-disable-next-line
  }, [isUpdateAvailable])

  return <Navigation />
}

export default App
