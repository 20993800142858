import styled from '@emotion/styled'
import * as React from 'react'
import { animated, useSpring } from 'react-spring'

type SideSliderProps = {
  slideWidth?: number
}

const PanelWrapper = styled(animated.div)<SideSliderProps>`
  display: flex;
  max-width: 100%;
  position: fixed;
  overflow-y: auto;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: ${(props) => (props.slideWidth ? `${props.slideWidth}vw` : '350px')};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SideSlider: React.FC<SideSliderProps> = ({ children, slideWidth }) => {
  const style = useSpring({
    to: {
      opacity: 1,
      transform: 'translate3d(0px,0,0)'
    },
    from: {
      opacity: 0,
      transform: slideWidth ? `translate3d(-${slideWidth}px,0,0)` : 'translate3d(-350px,0,0)'
    }
  })
  return (
    <PanelWrapper slideWidth={slideWidth} style={style}>
      {children}
    </PanelWrapper>
  )
}

export default SideSlider
