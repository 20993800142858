import { Button, ButtonGroup, Flex, useToast, Checkbox } from '@chakra-ui/core'
import { Field, Form, Formik, FormikProps, FormikActions } from 'formik'
import React from 'react'
import { Col, Container, Row, ConnectedSelect } from '../../components'
import ConnectedFormGroup from '../../components/ConnectedFormGroup/index'
import { Text } from '../../components/Typography'
import { theme } from '../../theme'
import { getCreateUserFormValidation } from './CreateUserFormUtils'
import { Auth } from 'aws-amplify'
import { ColorProps } from 'styled-system'
import Maybe from 'graphql/tsutils/Maybe'
import {
  CompanyGroupUserAssociation,
  CreateUserNotificationComponent
} from '../../generated/graphql'
import { LEGACY_COMPANY_LOOKUP } from '../../constants'
import { useAppContext } from '../../contexts/ApplicationProvider.context'
import { useQuery } from '@apollo/react-hooks'
import getUser from '../../graphql/queries/getUserProfile'
import { useAuthentication } from '../../hooks'
import ConnectedPhoneNumber from '../connectedPhoneNumber'

const INITIAL_VALUES = {
  name: '',
  surname: '',
  email: '',
  phoneNumber: '',
  companies: []
  // addressLine1: '',
  // addressLine2: '',
  // addressLine3: '',
  // addressLine4: '',
  // addressLine5: '',
  // role: UserRole.Admin,
  // status: UserStatus.Active,
  // password: '',
  // confirmPassword: ''
}

type FormValues = typeof INITIAL_VALUES

type CreateUserFromProps = ColorProps & {
  onClose?: () => void
  companyGroups: Maybe<CompanyGroupUserAssociation>[] | null | undefined
}

const CreateUserFrom: React.FC<CreateUserFromProps> = ({ onClose, companyGroups }) => {
  const toast = useToast()
  const [isSuperAdmin, setIsSuperAdmin] = React.useState(false)
  const { userProfile, setUserProfile } = useAppContext()
  const { getUser: getUserLocal } = useAuthentication()
  const { sub: id } = getUserLocal()

  const { data } = useQuery(getUser, { variables: { id } })

  React.useEffect(() => {
    if (data && data.getUser) {
      setUserProfile(data.getUser)
    }
  }, [data])

  const handleCheckAdmin = (event: any) => {
    setIsSuperAdmin(event.target.checked)
  }

  // Generate a random 10 character password and add it to state
  const tempPassword = (Math.random().toString(36) + '00000000000000000').slice(2, 12)

  return (
    <Row>
      <Col sm={12} md={12}>
        <CreateUserNotificationComponent>
          {(createUserNotification, { error, loading }) => (
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={getCreateUserFormValidation()}
              onSubmit={async (
                { name, surname, email, phoneNumber, companies }: FormValues,
                { setSubmitting, setError }: FormikActions<FormValues>
              ) => {
                // create user
                try {
                  setSubmitting(true)
                  setError('')
                  await Auth.signUp({
                    username: email,
                    password: tempPassword,
                    attributes: {
                      email,
                      'custom:phone': phoneNumber,
                      'custom:name': name,
                      'custom:surname': surname,
                      'custom:group': isSuperAdmin ? 'SUPER_ADMIN' : 'ADMIN',
                      'custom:tempPassword': tempPassword,
                      'custom:status': 'ACTIVE',
                      'custom:companies': companies
                        .map((company: any) => {
                          return company.value
                        })
                        .toString(),
                      'custom:companyGroups': [].toString()
                    }
                  })

                  await createUserNotification({
                    variables: {
                      input: {
                        createdAt: new Date().toISOString(),
                        userNotificationUserId: id,
                        icon: 'plusIcon',
                        message: `added a new user - ${email}`
                      }
                    }
                  })

                  setSubmitting(false)
                  if (onClose) {
                    onClose()
                  }
                  toast({
                    duration: 15000,
                    isClosable: true,
                    status: 'success',
                    title: 'Success!.',
                    description:
                      'New user invited successfully. They will appear in the table once they have confirmed their registration.',
                    position: 'top-right'
                  })
                } catch (err) {
                  setError(err.message)
                  setSubmitting(false)
                }
              }}
              render={({
                handleSubmit,
                error,
                isSubmitting,
                setFieldValue
              }: FormikProps<FormValues>) => {
                return (
                  <React.Fragment>
                    <Flex flexDirection="column" py={4}>
                      <Container style={{ width: '100%' }} fluid={true}>
                        <Row>
                          <Col sm={12} md={12}>
                            <Form>
                              <Row>
                                <Field name="name" label="Name" component={ConnectedFormGroup} />

                                <Field
                                  name="surname"
                                  label="Surname"
                                  component={ConnectedFormGroup}
                                />
                              </Row>
                              <Row>
                                <Field
                                  name="email"
                                  type="email"
                                  label="Email"
                                  component={ConnectedFormGroup}
                                />

                                <Field
                                  name="phoneNumber"
                                  label="Phone Number"
                                  component={ConnectedPhoneNumber}
                                  focusBorderColor={theme.colors.green[550]}
                                />
                                <Checkbox
                                  onChange={handleCheckAdmin}
                                  variantColor="green"
                                  marginBottom="1vh"
                                >
                                  {'Super Admin'}
                                </Checkbox>
                              </Row>

                              <Row>
                                <Field
                                  name="companies"
                                  label="Companies"
                                  isMulti
                                  options={
                                    userProfile && userProfile.dibCompanies
                                      ? userProfile.dibCompanies.map((companyId: any) => {
                                          return {
                                            value: companyId,
                                            label: LEGACY_COMPANY_LOOKUP[companyId.toString()]
                                          }
                                        })
                                      : []
                                  }
                                  component={ConnectedSelect}
                                  focusBorderColor={theme.colors.green[550]}
                                />
                              </Row>

                              {!!error && (
                                <Text
                                  textAlign="center"
                                  fontSize="md"
                                  color="red.500"
                                  fontWeight="lighter"
                                  mb={2}
                                >
                                  {error}
                                </Text>
                              )}
                            </Form>
                          </Col>
                        </Row>

                        <Flex justifyContent="flex-end">
                          <ButtonGroup spacing={4}>
                            <Button
                              type="submit"
                              backgroundColor={theme.colors.solid.white}
                              color="black"
                              borderWidth="1px"
                              isLoading={isSubmitting}
                              onClick={onClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              backgroundColor={theme.colors.green[550]}
                              color="white"
                              isLoading={isSubmitting}
                              //@ts-ignore
                              onClick={handleSubmit}
                            >
                              Add
                            </Button>
                          </ButtonGroup>
                        </Flex>
                      </Container>
                    </Flex>
                  </React.Fragment>
                )
              }}
            />
          )}
        </CreateUserNotificationComponent>
      </Col>
    </Row>
  )
}

export default CreateUserFrom
