import { theme as chakraTheme } from '@chakra-ui/core'

const theme = {
  ...chakraTheme,
  fonts: {
    body: 'Nunito Sans, Helvetica Neue, sans-serif',
    heading: 'Nunito Sans, Helvetica Neue, sans-serif',
    mono: 'Nunito Sans, Helvetica Neue, sans-serif'
  },
  colors: {
    ...chakraTheme.colors,
    blue: {
      50: '#e6f0fd',
      100: '#cae0fc',
      200: '#aacefa',
      300: '#85b9f8',
      400: '#579ef5',
      500: '#1478f2',
      600: '#126cda',
      700: '#0f5ebe',
      800: '#0d4e9d',
      900: '#09376f'
    },
    green: {
      50: '#ebf3ea',
      100: '#d5e7d3',
      200: '#bdd9ba',
      300: '#a1c99e',
      400: '#82b77d',
      500: '#5ba155',
      550: '#55a175',
      600: '#52914c',
      700: '#477f43',
      800: '#3b6937',
      900: '#2a4a27'
    },
    neutral: {
      lightest: '#F9F9FB',
      light: '#f0f1f3',
      base: '#404041',
      dark: '#222429'
    },
    brand: {
      primary: '#55a175',
      secondary: '#076f48',
      light: '#F5FAFA',
      accent: '#C9F2DC'
    },
    border: {
      default: '#E4E7EB',
      muted: '#EDF0F2'
    },
    text: {
      light: 'rgba(0, 0, 0, 0.45)',
      muted: '#66788A',
      default: '#425A70',
      grey: 'rgba(0, 0, 0, 0.65)',
      dark: '#222429'
    },
    intent: {
      success: '#47B881',
      danger: '#EC4C47',
      warning: '#D9822B',
      info: '#5AC1F2'
    },
    background: {
      tint1: '#F9F9FB',
      tint2: '#f5f5f5',
      tint3: '#bfbfbf',
      tint4: '#e9e9e9',
      tint5: '#E4E8EC'
    },
    solid: {
      black: '#000',
      white: '#FFF'
    },
    opacity: {
      transparent: 'transparent',
      transparentBlack: 'rgba(0,0,0,0.1)',
      transparentWhite: 'rgba(255,255,255,0.2)'
    }
  },
  transition: 'all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
}
// console.log('TCL: theme', theme)

export type Theme = typeof theme

export { theme }
