import { ColorProps } from 'styled-system'
import React from 'react'
import CreateUserForm from '../Forms/CreateUserForm'
import {
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/core'
import { theme } from '../../theme'
import Maybe from 'graphql/tsutils/Maybe'
import { CompanyGroupUserAssociation } from '../../generated/graphql'

type CreateUserProps = ColorProps & {
  companyGroups: Maybe<CompanyGroupUserAssociation>[] | null | undefined
}

const CreateUser: React.FC<CreateUserProps> = ({ companyGroups, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        mt={2}
        leftIcon="add"
        marginBottom="1vh"
        color={theme.colors.neutral.lightest}
        bg={theme.colors.green[550]}
        //@ts-ignore
        onClick={onOpen}
      >
        New Admin
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Admin</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex flexDirection="column">
              <CreateUserForm onClose={onClose} companyGroups={companyGroups}></CreateUserForm>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateUser
