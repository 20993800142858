import { ColorProps } from 'styled-system'
import React from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/core'

type AlertProps = ColorProps & {
  color?: string
  label?: string
  heading?: string
  body?: string
  cancel?: string
  action?: string
  onCancelClick?: () => void
  onActionClick?: () => void
}

/**
 * A custom alert dialog
 * @param label - The text to put in the button which opens the alert
 * @param heading - The alert dialog heading
 * @param body - The alert dialog body
 * @param cancel - The text to put in the cancel button
 * @param action - The text to put in the action button
 * @param onCancelClick - The function to call on cancel
 * @param onActionClick - The function to call on action
 */
const Dialog: React.FC<AlertProps> = ({
  label,
  heading,
  body,
  cancel,
  action,
  onCancelClick,
  onActionClick,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState()
  const onClose = () => setIsOpen(false)
  const cancelRef: any = React.useRef()

  return (
    <>
      <Button variantColor="red" onClick={() => setIsOpen(true)}>
        {label}
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {heading}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            {/* TODO: refactor button group */}
            <Button ref={cancelRef} onClick={onCancelClick}>
              {cancel}
            </Button>
            <Button variantColor="red" onClick={onActionClick} ml={3}>
              {action}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

Dialog.defaultProps = {
  heading: 'Alert',
  body: 'Are you sure you want to do this?',
  cancel: 'Cancel',
  action: 'Continue',
  onCancelClick: () => false,
  onActionClick: () => false
}

export default Dialog
