import { Flex, Input, Text } from '@chakra-ui/core'
import { InputProps } from '@chakra-ui/core/dist/Input'
import { ErrorMessage, FieldProps } from 'formik'
import * as React from 'react'
import { Label, LabelProps } from './styles'

export type ConnectedFormGroupProps = LabelProps &
  FieldProps &
  InputProps & {
    label?: string
  }

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  type,
  label,
  field,
  placeholder,
  ...rest
}) => (
  <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
    {label && <Label htmlFor={field.name}>{label}</Label>}
    {/* <Flex alignItems='center' paddingLeft='10px'> */}
    <Input
      {...field}
      type={type}
      id={field.name}
      placeholder={placeholder || label}
      {...rest}
      maxLength={200}
    />
    {/* <IconButton aria-label="Copy" icon="copy"> </IconButton>
    </Flex> */}
    <ErrorMessage name={field.name}>
      {(msg) => (
        <Text color="red.500" textAlign="right">
          {msg}
        </Text>
      )}
    </ErrorMessage>
  </Flex>
)

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text',
  fontWeight: 'lighter'
}
