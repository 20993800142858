const images = {
  driveribLogo: require('../assets/images/db-logo.png'),
  vpjLogo: require('../assets/images/vpj-logo.png'),
  vpjLogoDark: require('../assets/images/vpj-logo-dark.png'),
  landingBackground: require('../assets/images/background-dots.svg'),
  underConstruction: require('../assets/images/under-construction.svg'),
  plusIcon: require('../assets/images/plus.png'),
  mailIcon: require('../assets/images/mail.png'),
  starIcon: require('../assets/images/star.png'),
  redCircleIcon: require('../assets/images/redCircle.png')
}

export default images
